import React from 'react';

import style from './customInput.module.scss';

interface CustomInputProps {
  type?: string;
  label?: string;
  placeholder?: string;
  onChange?: any;
  styles?: string;
}
export const CustomInput: React.FC<CustomInputProps> = ({ type, label, placeholder }) => (
  <div className={style.customInputContainer}>
    {label && (
      <label className={style.customInputFieldLabel} htmlFor={`inputId-${label}`}>
        {label}
      </label>
    )}
    <input
      id={`inputId-${label}`}
      type={type}
      className={style.inputField}
      placeholder={placeholder}
    />
  </div>
);
