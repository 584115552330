const Units = ({
  color = '#212529',
  isCarbonFootPrint = false
}: {
  color?: string;
  isCarbonFootPrint?: boolean;
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={isCarbonFootPrint ? '24' : '32'}
    height={isCarbonFootPrint ? '24' : '32'}
    viewBox="0 0 33 33"
    fill="none">
    <path
      d="M28.2686 8.76828L17.2686 2.74953C16.9747 2.58714 16.6444 2.50195 16.3086 2.50195C15.9728 2.50195 15.6425 2.58714 15.3486 2.74953L4.34859 8.77078C4.03445 8.94267 3.77222 9.19573 3.58928 9.50357C3.40634 9.8114 3.30941 10.1627 3.30859 10.5208V22.4758C3.30941 22.8339 3.40634 23.1852 3.58928 23.493C3.77222 23.8008 4.03445 24.0539 4.34859 24.2258L15.3486 30.247C15.6425 30.4094 15.9728 30.4946 16.3086 30.4946C16.6444 30.4946 16.9747 30.4094 17.2686 30.247L28.2686 24.2258C28.5827 24.0539 28.845 23.8008 29.0279 23.493C29.2108 23.1852 29.3078 22.8339 29.3086 22.4758V10.522C29.3085 10.1633 29.2118 9.81123 29.0289 9.50268C28.8459 9.19413 28.5833 8.94047 28.2686 8.76828ZM16.3086 4.49953L26.3511 9.99953L16.3086 15.4995L6.26609 9.99953L16.3086 4.49953ZM5.30859 11.7495L15.3086 17.222V27.9458L5.30859 22.477V11.7495ZM17.3086 27.9458V17.227L27.3086 11.7495V22.472L17.3086 27.9458Z"
      fill={color}
    />
  </svg>
);

export default Units;
