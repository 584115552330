export const productEvaluationData = {
  evaluations: [
    {
      name: 'Energy Impact Calculator',
      icon: 'Energy Impact Calculator',
      details: [
        {
          name: 'Biomedical Equipment and Supplies / Thermometers',
          type: 'bool',
          value: 'Not Compliant'
        },
        {
          name: 'Biomedical Equipment and Supplies / pH Sensor Electrode',
          type: 'bool',
          value: 'Not Compliant'
        },
        {
          name: 'Biomedical Equipment and Supplies / pH Sensor Electrode',
          type: 'bool',
          value: 'Not Compliant'
        },
        {
          name: 'Biomedical Equipment and Supplies / pH Sensor Electrode',
          type: 'bool',
          value: 'Not Compliant'
        },
        {
          name: 'Biomedical Equipment and Supplies / pH Sensor Electrode',
          type: 'bool',
          value: 'Not Compliant'
        }
      ]
    }
  ],

  lens_data: [
    {
      name: 'Certifications and Standards',
      image: 'https://static.ecomedesstaging.com/lenses/Certification.png',
      kind: '',
      notes: '',
      details: {
        name: 'Certifications and Standards',
        description: null,
        details: [
          {
            attribute: 'cal-g',
            description: '',
            hint: 'LIXIL, 2023-02-09',
            ident: 'cal-g',
            input: 6135,
            kind: 'bool',
            name: 'CALGreen',
            source: 'lixil',
            value: true
          },
          {
            attribute: 'cal-g',
            description: '',
            hint: 'LIXIL, 2023-02-09',
            ident: 'cal-g',
            input: 6135,
            kind: 'bool',
            name: 'CALGreen',
            source: 'lixil',
            value: true
          },
          {
            attribute: 'cal-g',
            description: '',
            hint: 'LIXIL, 2023-02-09',
            ident: 'cal-g',
            input: 6135,
            kind: 'bool',
            name: 'CALGreen',
            source: 'lixil',
            value: true
          },
          {
            attribute: 'cal-g',
            description: '',
            hint: 'LIXIL, 2023-02-09',
            ident: 'cal-g',
            input: 6135,
            kind: 'bool',
            name: 'CALGreen',
            source: 'lixil',
            value: true
          }
        ]
      }
    }
  ]
};
