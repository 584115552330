import React from 'react';

import { ProjectDetailsProps } from '../../interface/projectSummary';

import BuildingIcon from './../../assets/svgs/building';
import HouseIcon from './../../assets/svgs/house';
import LocationIcon from './../../assets/svgs/location';
import SizeIcon from './../../assets/svgs/size';
import Padding from './Padding';

import style from './sustainabilitySummary.module.scss';
const ProjectDetails: React.FC<ProjectDetailsProps> = ({
  postalCode,
  projectSize,
  clientName,
  address,
  description
}) => (
  <Padding>
    <div className={style.projectDetails}>
      <h2 className={`${style.ecomedesTitle} ${!description && style.visibilityHiddenClass}`}>
        {description && <span className={style.ecomedesSubTitle}> {description} </span>}
      </h2>
      <div className={`${style.detailItems} detailItemsPdf`}>
        <div className={style.detailsSection}>
          <div className={style.detailItem}>
            <LocationIcon />
            <span className={`${style.detailItemTitle} ${style.postalCodeKey}`}>Postal Code:</span>
            <span>{postalCode || '-'}</span>
          </div>
        </div>
        <div className={style.verticalLine}></div>
        <div className={style.detailsSection}>
          <div className={style.detailItem}>
            <SizeIcon />
            <span className={`${style.detailItemTitle} ${style.projectSizeKey}`}>
              Project Size (sq ft):
            </span>
            <span>{projectSize === 'null' ? '-' : (1 * projectSize).toLocaleString()}</span>
          </div>
        </div>
        <div className={style.verticalLine}></div>
        <div className={style.detailsSection}>
          <div className={style.detailItem}>
            <BuildingIcon />
            <span className={`${style.detailItemTitle} ${style.clientNameKey}`}>Client Name:</span>
            <span className={style.clientNameValue}>{clientName || '-'}</span>
          </div>
        </div>
        <div className={style.verticalLine}></div>
        <div className={style.detailsSection}>
          <div className={`${style.detailItem}`}>
            <HouseIcon /> <span className={style.detailItemTitle}>Address:</span>{' '}
            <span className={style.detailItemAddress}>{address || '-'}</span>
          </div>
        </div>
      </div>
    </div>
  </Padding>
);

export default ProjectDetails;
