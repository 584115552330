const Cash = ({ isEvalPage = false }: { isEvalPage?: boolean }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
    <path
      d="M16 11.5C15.0111 11.5 14.0444 11.7932 13.2221 12.3427C12.3999 12.8921 11.759 13.673 11.3806 14.5866C11.0022 15.5002 10.9031 16.5055 11.0961 17.4755C11.289 18.4454 11.7652 19.3363 12.4645 20.0355C13.1637 20.7348 14.0546 21.211 15.0245 21.4039C15.9945 21.5969 16.9998 21.4978 17.9134 21.1194C18.827 20.741 19.6079 20.1001 20.1573 19.2779C20.7068 18.4556 21 17.4889 21 16.5C21 15.1739 20.4732 13.9021 19.5355 12.9645C18.5979 12.0268 17.3261 11.5 16 11.5ZM16 19.5C15.4067 19.5 14.8266 19.3241 14.3333 18.9944C13.8399 18.6648 13.4554 18.1962 13.2284 17.6481C13.0013 17.0999 12.9419 16.4967 13.0576 15.9147C13.1734 15.3328 13.4591 14.7982 13.8787 14.3787C14.2982 13.9591 14.8328 13.6734 15.4147 13.5576C15.9967 13.4419 16.5999 13.5013 17.1481 13.7284C17.6962 13.9554 18.1648 14.3399 18.4944 14.8333C18.8241 15.3266 19 15.9067 19 16.5C19 17.2956 18.6839 18.0587 18.1213 18.6213C17.5587 19.1839 16.7956 19.5 16 19.5ZM30 7.5H2C1.73478 7.5 1.48043 7.60536 1.29289 7.79289C1.10536 7.98043 1 8.23478 1 8.5V24.5C1 24.7652 1.10536 25.0196 1.29289 25.2071C1.48043 25.3946 1.73478 25.5 2 25.5H30C30.2652 25.5 30.5196 25.3946 30.7071 25.2071C30.8946 25.0196 31 24.7652 31 24.5V8.5C31 8.23478 30.8946 7.98043 30.7071 7.79289C30.5196 7.60536 30.2652 7.5 30 7.5ZM24.2062 23.5H7.79375C7.45801 22.3645 6.84351 21.3311 6.00623 20.4938C5.16895 19.6565 4.1355 19.042 3 18.7062V14.2937C4.1355 13.958 5.16895 13.3435 6.00623 12.5062C6.84351 11.6689 7.45801 10.6355 7.79375 9.5H24.2062C24.542 10.6355 25.1565 11.6689 25.9938 12.5062C26.8311 13.3435 27.8645 13.958 29 14.2937V18.7062C27.8645 19.042 26.8311 19.6565 25.9938 20.4938C25.1565 21.3311 24.542 22.3645 24.2062 23.5ZM29 12.1713C27.8005 11.6555 26.8445 10.6995 26.3288 9.5H29V12.1713ZM5.67125 9.5C5.15549 10.6995 4.19945 11.6555 3 12.1713V9.5H5.67125ZM3 20.8288C4.19945 21.3445 5.15549 22.3005 5.67125 23.5H3V20.8288ZM26.3288 23.5C26.8445 22.3005 27.8005 21.3445 29 20.8288V23.5H26.3288Z"
      fill={isEvalPage ? '#343A40' : '#49B108'}
    />
  </svg>
);

export default Cash;
