import React from 'react';

import css from './iconBox.module.scss';

interface IconBoxProps {
  icon: JSX.Element;
  title?: string;
  className?: string;
}

const IconBox: React.FC<IconBoxProps> = ({ icon, title, className }: IconBoxProps) => (
  <div className={`${title ? css.iconBox : css.iconBoxDefault} ${className}`}>
    <div className={css.statisticIcons}>{icon}</div>
    {title && <span className={css.gridTitle}>{title}</span>}
  </div>
);

export default IconBox;
