import React from 'react';

export const MenuIcon: React.FC = () => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.645917 0.646894C0.692363 0.600331 0.747538 0.563388 0.808283 0.538181C0.869029 0.512975 0.93415 0.5 0.999917 0.5C1.06568 0.5 1.13081 0.512975 1.19155 0.538181C1.2523 0.563388 1.30747 0.600331 1.35392 0.646894L7.35392 6.64689C7.40048 6.69334 7.43742 6.74852 7.46263 6.80926C7.48784 6.87001 7.50081 6.93513 7.50081 7.00089C7.50081 7.06666 7.48784 7.13178 7.46263 7.19253C7.43742 7.25327 7.40048 7.30845 7.35392 7.35489L1.35392 13.3549C1.26003 13.4488 1.13269 13.5015 0.999917 13.5015C0.867141 13.5015 0.739804 13.4488 0.645917 13.3549C0.55203 13.261 0.499285 13.1337 0.499285 13.0009C0.499285 12.8681 0.55203 12.7408 0.645917 12.6469L6.29292 7.00089L0.645917 1.35489C0.599354 1.30845 0.562411 1.25327 0.537204 1.19253C0.511998 1.13178 0.499023 1.06666 0.499023 1.00089C0.499023 0.935127 0.511998 0.870005 0.537204 0.80926C0.562411 0.748515 0.599354 0.69334 0.645917 0.646894Z"
      fill="#212529"
    />
  </svg>
);
