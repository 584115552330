const OverallSaving = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 2.25C6.95344 2.25 3 4.55625 3 7.5V16.5C3 19.4438 6.95344 21.75 12 21.75C17.0466 21.75 21 19.4438 21 16.5V7.5C21 4.55625 17.0466 2.25 12 2.25ZM19.5 12C19.5 12.9019 18.7612 13.8216 17.4741 14.5238C16.0247 15.3141 14.0803 15.75 12 15.75C9.91969 15.75 7.97531 15.3141 6.52594 14.5238C5.23875 13.8216 4.5 12.9019 4.5 12V10.44C6.09938 11.8463 8.83406 12.75 12 12.75C15.1659 12.75 17.9006 11.8425 19.5 10.44V12ZM6.52594 4.97625C7.97531 4.18594 9.91969 3.75 12 3.75C14.0803 3.75 16.0247 4.18594 17.4741 4.97625C18.7612 5.67844 19.5 6.59812 19.5 7.5C19.5 8.40188 18.7612 9.32156 17.4741 10.0237C16.0247 10.8141 14.0803 11.25 12 11.25C9.91969 11.25 7.97531 10.8141 6.52594 10.0237C5.23875 9.32156 4.5 8.40188 4.5 7.5C4.5 6.59812 5.23875 5.67844 6.52594 4.97625ZM17.4741 19.0238C16.0247 19.8141 14.0803 20.25 12 20.25C9.91969 20.25 7.97531 19.8141 6.52594 19.0238C5.23875 18.3216 4.5 17.4019 4.5 16.5V14.94C6.09938 16.3463 8.83406 17.25 12 17.25C15.1659 17.25 17.9006 16.3425 19.5 14.94V16.5C19.5 17.4019 18.7612 18.3216 17.4741 19.0238Z"
      fill="#343A40"
    />
  </svg>
);

export default OverallSaving;
