export const Gallons = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <rect width="40" height="40" rx="8" fill="#F2F2F2" />
    <rect x="7" y="6" width="26" height="28" fill="#343A40" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4885 12.0125L18.4199 13.0811L23.2552 17.9163L23.2685 15.8504C23.2685 15.8148 23.2552 15.7792 23.2285 15.7525L19.484 12.0081L19.4885 12.0125Z"
      fill="#F2F2F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.461 9.3811L7.87598 20.9661L17.5242 30.6144L24.7371 23.4016L24.6836 14.6037L19.461 9.3811ZM23.9624 18.4328C23.9624 18.6331 23.8377 18.8112 23.6551 18.8913C23.5928 18.9181 23.5305 18.927 23.4681 18.927C23.339 18.927 23.2144 18.878 23.1164 18.78L17.8849 13.5485C17.6267 13.2903 17.6267 12.8718 17.8849 12.6135L19.0247 11.4737C19.2829 11.2155 19.7015 11.2155 19.9597 11.4737L23.7397 15.2538C23.9 15.4141 23.9891 15.6322 23.9846 15.8548L23.9668 18.4328H23.9624Z"
      fill="#F2F2F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.4609 20.3918V17.6804V16.5005H25.4502V21.6073H26.4609V20.3918Z"
      fill="#F2F2F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.4932 26.9411C27.4932 28.2635 28.5706 29.341 29.893 29.341C31.2153 29.341 32.2928 28.2635 32.2928 26.9411C32.2928 25.9171 30.7568 23.5529 29.893 22.3463C29.0292 23.5529 27.4932 25.9171 27.4932 26.9411ZM30.129 28.8245C30.0533 28.8334 29.9731 28.8379 29.893 28.8379C28.771 28.8379 27.8583 27.9251 27.8583 26.8031C27.8583 26.6562 27.885 26.487 27.9473 26.2956L28.6241 26.5048C28.5795 26.6562 28.5662 26.7497 28.5662 26.8031C28.5662 27.5333 29.1583 28.1255 29.8885 28.1255C29.942 28.1255 29.9909 28.1255 30.0444 28.1166L30.1245 28.82L30.129 28.8245Z"
      fill="#F2F2F2"
    />
    <path d="M28.0099 18.3883H27.1729V19.6839H28.0099V18.3883Z" fill="#F2F2F2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0C8.9537 0 0 8.9537 0 20C0 31.0463 8.9537 40 20 40C31.0463 40 40 31.0463 40 20C40 8.9537 31.0463 0 20 0ZM25.3651 23.7845L17.7382 31.4114C17.6224 31.5272 17.431 31.5272 17.3108 31.4114L7.08371 21.1843C6.96795 21.0686 6.96795 20.8771 7.08371 20.7569L19.2476 8.59305C19.3633 8.47729 19.5548 8.47729 19.675 8.59305L25.3072 14.2253C25.3606 14.2787 25.3918 14.3544 25.3918 14.4301L25.4007 15.7925H26.9991C27.0926 15.7925 27.1728 15.8682 27.1728 15.9662V17.6803H28.5485C28.642 17.6803 28.7222 17.756 28.7222 17.854V20.2226C28.7222 20.3161 28.6465 20.3963 28.5485 20.3963H27.1728V22.146C27.1728 22.2395 27.0971 22.3197 26.9991 22.3197H25.4408L25.4497 23.5752C25.4497 23.6554 25.4185 23.7266 25.3651 23.7845ZM29.8932 30.0534C28.179 30.0534 26.7809 28.6598 26.7809 26.9412C26.7809 25.3473 29.3188 21.919 29.6082 21.5316L29.8932 21.1532L30.1781 21.5316C30.4675 21.919 33.0053 25.3473 33.0053 26.9412C33.0053 28.6554 31.6118 30.0534 29.8932 30.0534Z"
      fill="#F2F2F2"
    />
  </svg>
);
