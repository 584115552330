import React from 'react';

import { stateOptions } from '../../utils/selectOptions';
import { CustomInput } from '../shared/CustomInput';
import { CustomMenu } from '../shared/CustomMenu';
import { CustomSelect } from '../shared/CustomSelect';

import style from './productDetails.module.scss';
const ModalBody: React.FC = () => (
  <div>
    <form className={style.addProjectModalForm}>
      <CustomSelect
        label="Projects"
        options={stateOptions}
        components={CustomMenu}
        placeholder={'Select project'}
      />

      <div className={style.addProjectModalFormInputContainer}>
        <CustomInput type="text" label={'Project Name'} placeholder={'Enter project name'} />
      </div>
      <div className={style.addProjectModalFormInputContainer}>
        <CustomInput type="text" label={'Postal Code'} placeholder={'Enter postal code'} />
      </div>
    </form>
  </div>
);

export default ModalBody;
