/* eslint-disable max-params */
import { PageTemplateProps, savePDF } from '@progress/kendo-react-pdf';

// eslint-disable-next-line max-params
export const handlePDFDownload = (
  pdfRef: React.RefObject<HTMLDivElement>,
  setIsPdfLoading: React.Dispatch<React.SetStateAction<boolean>>,
  generatePageTemplate: (
    { pageNum, totalPages }: PageTemplateProps,
    url: string,
    address?: string | null
  ) => JSX.Element,
  name?: any,
  // eslint-disable-next-line max-params
  projectName?: any,
  address?: string | null | undefined
): void => {
  setIsPdfLoading(true);

  if (pdfRef.current) {
    const element = pdfRef.current;

    savePDF(
      element,
      {
        repeatHeaders: true,
        paperSize: 'A4',
        scale: 0.4,
        margin: {
          bottom: '1.7cm',
          top: '0.5cm',
          right: '0.2cm',
          left: '0.2cm'
        },
        fileName: name || 'Impact Summary',
        pageTemplate: (props: PageTemplateProps) =>
          generatePageTemplate(props, projectName?.ecomedes_logo, address) // Pass additional URL
      },
      () => {
        setIsPdfLoading(false);
      }
    );
  }
};
