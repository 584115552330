export const CarbonCloud = ({ greenTags = false }: { greenTags?: boolean }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={greenTags ? '24' : '32'}
    height={greenTags ? '24' : '32'}
    viewBox="0 0 32 32"
    fill="none">
    <path
      d="M24.1601 14.2445C24.1601 14.1707 24.1724 14.0969 24.1724 14.023C24.1724 10.0671 20.9239 6.8125 16.9864 6.8125C14.1502 6.8125 11.7077 8.529 10.5634 10.9346C10.065 10.6823 9.51133 10.5408 8.91455 10.5408C7.09346 10.5408 5.58613 11.9989 5.29082 13.74C3.1252 14.4906 1.5625 16.4594 1.5625 18.8957C1.5625 21.9596 4.02959 24.5312 7.075 24.5312H24.0801C26.8486 24.5312 29.125 22.138 29.125 19.3448C29.125 16.5394 26.9471 14.2507 24.1601 14.2445ZM24.0493 23.5223L23.8524 23.5469H7.12422C4.60791 23.5469 2.55918 21.3997 2.55918 18.8649C2.55918 16.9023 3.80195 15.272 5.64766 14.6321L6.16445 14.4476L6.25674 13.9062C6.47822 12.5772 7.61641 11.4883 8.95762 11.4883C9.38213 11.4883 9.80049 11.5867 10.1819 11.7836L11.0125 12.202L11.4124 11.3591C12.4337 9.21191 14.6178 7.77842 16.9741 7.77842C20.3764 7.77842 23.1818 10.4916 23.1818 13.9123C23.1818 14.7306 23.1695 15.1612 23.1695 15.1612L24.1047 15.1674C26.3626 15.1981 28.1406 17.0438 28.1406 19.3141C28.1406 21.5843 26.3072 23.51 24.0493 23.5223Z"
      fill="black"
    />
    <path
      d="M21.9591 20.6159C20.9616 20.6159 20.1807 19.8875 20.1807 18.8178V18.8047C20.1807 17.8072 20.8829 17 21.8738 17C22.9763 17 23.5341 17.8663 23.5341 18.8638C23.5341 18.9359 23.5275 19.0081 23.521 19.0869H20.9747C21.06 19.6578 21.4538 19.9663 21.9722 19.9663C22.366 19.9663 22.6285 19.8219 22.9107 19.5528L23.3766 19.9663C23.0419 20.36 22.5957 20.6159 21.9591 20.6159ZM20.9682 18.5619H22.7466C22.6941 18.0566 22.3922 17.6497 21.8672 17.6497C21.375 17.6497 21.0404 18.0172 20.9682 18.5619Z"
      fill="black"
    />
    <path
      d="M17.4141 22.0127V21.6028L18.4324 20.7366C18.8338 20.3902 18.9859 20.2085 18.9859 19.938C18.9859 19.6507 18.7789 19.4775 18.5084 19.4775C18.238 19.4775 18.0521 19.6211 17.8281 19.9211L17.4563 19.6296C17.7394 19.2366 18.0225 19.0127 18.5465 19.0127C19.1211 19.0127 19.5225 19.3676 19.5225 19.8958V19.9042C19.5225 20.3733 19.2774 20.6183 18.7535 21.0493L18.145 21.5564H19.5521V22.0127H17.4141Z"
      fill="black"
    />
    <path
      d="M14.2996 20.7512C12.8887 20.7512 11.9043 19.6816 11.9043 18.3888V18.3756C11.9043 17.0894 12.9018 16 14.3127 16C15.7237 16 16.708 17.0697 16.708 18.3625V18.3756C16.708 19.6619 15.7105 20.7512 14.2996 20.7512ZM14.3127 20.0031C15.2249 20.0031 15.8615 19.2813 15.8615 18.3888V18.3756C15.8615 17.4831 15.2184 16.7481 14.2996 16.7481C13.3874 16.7481 12.7509 17.47 12.7509 18.3625V18.3756C12.7509 19.2681 13.394 20.0031 14.3127 20.0031Z"
      fill="black"
    />
    <path
      d="M9.51691 20.7512C8.17816 20.7512 7.18066 19.7078 7.18066 18.3888V18.3756C7.18066 17.0697 8.15848 16 9.54316 16C10.3963 16 10.9082 16.2953 11.3479 16.7153L10.8294 17.3125C10.4554 16.9712 10.0682 16.7481 9.5366 16.7481C8.67035 16.7481 8.02723 17.4634 8.02723 18.3625V18.3756C8.02723 19.2747 8.66379 20.0031 9.54316 20.0031C10.101 20.0031 10.4685 19.78 10.8622 19.4125L11.3807 19.9375C10.9147 20.4428 10.37 20.7512 9.51691 20.7512Z"
      fill="black"
    />
  </svg>
);
