/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { RefObject, useEffect, useState } from 'react';

import { CarbonCloud } from '../../assets/svgs/carbonCloud';
import { Global } from '../../assets/svgs/global';
import GrayCashSvg from '../../assets/svgs/grayCashSvg';
import Info from '../../assets/svgs/info';
import MindfulMaterials from '../../assets/svgs/mindfulMaterials';
import { WaterDropIcon } from '../../assets/svgs/waterDrop';
import { ProductsData } from '../../interface/projectSummary';
import { carbonFootPrint } from '../../utils/carbonFootPrint';
import mapProductToGrids from '../../utils/filterTableData';
import { funFactObject } from '../../utils/impactSummary/funFact';
import { Customheaders } from '../../utils/impactSummary/tableHeader';
import { Box } from '../shared/Box/box';
import FallbackContainer from '../shared/ErrorBoundary/FallbackContainer';
import IconBox from '../shared/IconBox/iconBox';
import { ModalComponent } from '../shared/Modal/Modal';
import { PostiveImpactSummaryCard } from '../shared/PostiveImpactSummaryCard/postiveImpactSummaryCard';
import Table from '../Table/Table';

import CashIcon from './../../assets/svgs/cash';
import CertificationsIcon from './../../assets/svgs/certification';
import CircularityIcon from './../../assets/svgs/circularity';
import RatingIcon from './../../assets/svgs/rating';
import SkuIcon from './../../assets/svgs/sku';
import UnitsIcon from './../../assets/svgs/units';
import Header from './Header';
import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import Navbar from './Navbar';
import ProjectDetails from './ProjectDetails';
import StatisticItem from './StatisticItem';

import style from './sustainabilitySummary.module.scss';

const gridIcons: { [key: string]: JSX.Element } = {
  rating_icon: <RatingIcon />,
  certifications_icon: <CertificationsIcon />,
  circularity_icon: <CircularityIcon />,
  embodied_carbon_icon: <CarbonCloud />,
  product_spend: <GrayCashSvg />,
  mm_cmf: <MindfulMaterials />,
  water_efficiency_icon: <WaterDropIcon />
};

export interface ISummary {
  cost: number;
  sku: number;
  units: number;
}

export interface IImpact {
  qunatity: number;
  icon: React.ReactElement;
  start: string;
  middle: string;
  end: string;
  bolder: string;
  hasBackground?: boolean;
}

const StatisticsContainer = ({ summary }: { summary: ISummary }): JSX.Element => (
  <div className={style.statisticsContainer}>
    <div className={`${style.statistics} statisticsPdf`}>
      <StatisticItem title={'Total SKUs'} amount={summary.sku} icon={<SkuIcon />} />
      <StatisticItem title={'Total Units'} amount={summary.units} icon={<UnitsIcon />} />
      <StatisticItem
        className={style.statisticIconsBg}
        symbol={'$'}
        title={'Total Cost'}
        amount={summary.cost}
        icon={<CashIcon />}
      />
    </div>
  </div>
);
const SustainabilitySummary = ({
  productList,
  pdfRef,
  backToProject,
  trackFn,
  projectName
}: {
  productList: any;
  pdfRef: RefObject<HTMLDivElement>;
  projectName: any;
  backToProject: () => {};
  trackFn: (action: string) => {};
}): JSX.Element => {
  const [productData, setProductData] = useState<ProductsData>({});
  const [products, setProducts] = useState<ProductsData>({});
  const [summaryData, setSummaryData] = useState<any>(null);
  const [funFact, setFunFact] = useState<any>([]);
  const [contributingUnitsData, setContributingUnitsData] = useState<number>(0);
  const [carbonSummary, setCarbonSummary] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pdfList, setPdfList] = useState<any>([]);
  const [showErrorBoundaryinPDF, setShowErrorBoundaryinPDF] = useState<any>({});
  const [checkBoxTables, setCheckBoxTables] = useState<any>([]);
  const getPdfList = (gridList: any) => {
    const result: { key: string; value: boolean }[] = [];

    Object.keys(gridList).forEach((key) => {
      result.push({ key, value: gridList[key]?.hide_by_default || false });
    });
    setPdfList(result);
    setCheckBoxTables(result);
  };
  const toggle = (): void => setIsModalOpen(!isModalOpen);
  const toggleCheckBox = (key: any): void => {
    if (key === 'Positive Impacts on the Planet') {
      setCheckBoxTables(
        checkBoxTables?.map((el: { key: any; value: any }) =>
          el.key === key ? { ...el, value: !el.value } : el
        )
      );
    }

    const updateProducts = { ...products };

    updateProducts[key] = {
      ...updateProducts[key],
      hide_by_default: !updateProducts[key].hide_by_default
    };

    setProducts(updateProducts);
    setCheckBoxTables(
      checkBoxTables?.map((el: { key: any; value: any }) =>
        el.key === key ? { ...el, value: !el.value } : el
      )
    );
  };
  const handleSave = (): void => {
    setPdfList(checkBoxTables);
    setProductData(products);
    toggle();
  };
  const handleCancel = (): void => {
    setCheckBoxTables(pdfList);
    toggle();
  };

  useEffect(() => {
    const { grid, summary } = mapProductToGrids(productList);

    getPdfList(grid);

    if (!!productList?.fun_facts && Object.keys(productList?.fun_facts).length > 0) {
      setPdfList((prevList: any) => [
        { key: 'Positive Impacts on the Planet', value: false },
        ...prevList
      ]);
      setCheckBoxTables((prevList: any) => [
        { key: 'Positive Impacts on the Planet', value: false },
        ...prevList
      ]);
    }

    if (productList?.fun_facts) {
      const { factsObject, contributingUnits } = funFactObject(productList?.fun_facts);

      setContributingUnitsData(contributingUnits);
      setFunFact(factsObject);
    }

    setProductData(grid);
    setProducts(grid);

    setSummaryData(summary);
  }, [productList]);

  useEffect(() => {
    if (productData['Carbon Summary']) {
      const carbonSummaryData = productData['Carbon Summary']?.data;

      setCarbonSummary(carbonFootPrint(carbonSummaryData));
    }
  }, [productData]);

  const showFunfact = () =>
    pdfList.filter(
      (data: any) => data.key === 'Positive Impacts on the Planet' && data.value === false
    );

  return (
    <>
      <div className={style.sustainabilitySummaryContainer}>
        <div className={`${style.sustainabilitySummary} sustainabilitySummaryHeaderHeight`}>
          <FallbackContainer
            component="Navbar"
            showErrorBoundaryinPDF={showErrorBoundaryinPDF}
            setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
            <Navbar projectName={projectName} backToProject={backToProject} />
          </FallbackContainer>
          <FallbackContainer
            component="Header"
            showErrorBoundaryinPDF={showErrorBoundaryinPDF}
            setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
            <Header
              projectName={projectName}
              pdfRef={pdfRef}
              name={productList.project.name}
              trackFn={trackFn}
              address={productList.project?.address}
              toggle={toggle}
            />
          </FallbackContainer>
          <div className={style.divider}></div>
          <FallbackContainer
            component="ProjectDetails"
            showErrorBoundaryinPDF={showErrorBoundaryinPDF}
            setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
            <ProjectDetails
              postalCode={productList.project?.['postal_code'] || productList.project?.location}
              clientName={productList.project?.['client_name']}
              projectSize={`${productList.project?.size}`}
              address={productList.project?.address}
              description={productList.project?.description}
              name={productList.project.name}
            />
          </FallbackContainer>
          {summaryData && (
            <FallbackContainer
              component="StatisticsContainer"
              showErrorBoundaryinPDF={showErrorBoundaryinPDF}
              setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
              <StatisticsContainer summary={summaryData} />
            </FallbackContainer>
          )}
        </div>
        <div
          className={
            productList.project?.address?.length > 30 ? style.marginTop : style.margin
          }></div>

        {!!carbonSummary?.length && (
          <div className={style.ratingContainer}>
            <Box>
              <>
                <div className={style.iconBoxContainer}>
                  <IconBox
                    icon={<CarbonCloud />}
                    title={'Carbon Footprint'}
                    className={style.iconBoxLeftPadding}
                  />
                </div>

                <div className={`${style.divider} ${style.dividerColor}`}></div>
                <div className={style.postiveImpactSummaryCarbonCardContainer}>
                  {carbonSummary?.map((el: any, _index: any) => (
                    <FallbackContainer
                      component="PostiveImpactSummaryCard"
                      showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                      setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                      <PostiveImpactSummaryCard item={el} isCarbonFootPrint />
                    </FallbackContainer>
                  ))}
                </div>
              </>
            </Box>
          </div>
        )}

        <div className={showFunfact().length ? '' : style.hideElements}>
          {!!funFact?.length && (
            <div className={style.ratingContainer}>
              <Box>
                <>
                  <div className={style.funfactContainer}>
                    <IconBox
                      icon={<Global />}
                      title={'Positive Impacts on the Planet'}
                      className={style.iconBoxLeftPadding}
                    />
                    <div className={style.unitsInfoSection}>
                      <div className={style.iconBoxInfoSection}>
                        <p className={`iconBoxInfoSectionPdfContent`}>
                          {(contributingUnitsData &&
                            (contributingUnitsData * 100)?.toFixed(0).toLocaleString()) ||
                            0}
                          % of Units
                        </p>
                        <Info />
                      </div>
                    </div>
                  </div>

                  <div className={`${style.divider} ${style.dividerColor}`}></div>
                  <div className={style.postiveImpactSummaryCardContainer}>
                    {funFact.map((el: any, _index: any) => (
                      <FallbackContainer
                        component="PostiveImpactSummaryCard"
                        showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                        setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                        <PostiveImpactSummaryCard item={el} />
                      </FallbackContainer>
                    ))}
                  </div>
                </>
              </Box>
            </div>
          )}
        </div>

        <div className={style.tableContainer}>
          {!!Object.keys(productData)?.length &&
            Object.keys(productData).map((el) => (
              <div
                key={el}
                className={`${productData[el]?.hide_by_default ? `${style.hideElements} hidePdfElements` : ''}`}>
                <div className={style.marginIconBox}>
                  <FallbackContainer
                    component="IconBox"
                    showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                    setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                    <IconBox
                      icon={gridIcons[productData[el]?.icon_name as keyof typeof gridIcons]}
                      title={el}
                    />
                  </FallbackContainer>
                </div>
                <FallbackContainer
                  component="Table"
                  showErrorBoundaryinPDF={showErrorBoundaryinPDF}
                  setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
                  <Table
                    data={productData[el]?.data}
                    columns={productData[el]?.columns}
                    customHeaders={
                      Customheaders[productData[el]?.icon_name as keyof typeof gridIcons]
                    }
                    footer={productData[el]?.footer}
                  />
                </FallbackContainer>
                <div className={style.footnote}>
                  {productData[el]?.footnote && productData[el]?.footnote}
                </div>
              </div>
            ))}
        </div>
      </div>
      <FallbackContainer
        component="ModalComponent"
        showErrorBoundaryinPDF={showErrorBoundaryinPDF}
        setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
        <ModalComponent
          isOpen={isModalOpen}
          modalBody={<ModalBody pdfList={checkBoxTables} toggleCheckBox={toggleCheckBox} />}
          modalHeader={<ModalHeader />}
          modalFooter={<ModalFooter onSave={handleSave} onCancel={handleCancel} />}
        />
      </FallbackContainer>
    </>
  );
};

export default SustainabilitySummary;
