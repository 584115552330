import React, { useState } from 'react';

import style from './Breadcrumb.module.scss';

interface BreadcrumbItemProps {
  href?: string;
  text: string;
  handleNavClick?: (navItem: string) => void;
  activeItem?: string;
}

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
  href,
  text,
  handleNavClick,
  activeItem
}) => (
  <a
    href={href}
    className={`${style.breadcrumbItemLink} ${text === activeItem && style.active}`}
    onClick={() => {
      if (handleNavClick) {
        handleNavClick(text);
      }
    }}>
    {text}
  </a>
);
interface BreadcrumbProps {
  navItems: BreadcrumbItemProps[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ navItems }) => {
  const [activeItem, setActiveItem] = useState(navItems[0]?.text);
  const handleNavClick = (navItem: string): void => {
    setActiveItem(navItem);
  };

  return (
    <div className={style.breadcrumbContainer}>
      <div className={style.breadcrumbMain}>
        {navItems?.map((item, index) => (
          <div className={style.breadcrumbItem} key={index}>
            <BreadcrumbItem {...item} handleNavClick={handleNavClick} activeItem={activeItem} />
            {navItems.length > -1 && index !== navItems?.length - 1 && <div>{'/'}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumb;
