import { generateBaseUrl } from '../../../utils/generateBaseUrl';

import style from './redirect.module.scss';
export const Redirect = ({
  specifier,
  trackFn
}: {
  specifier: string;
  trackFn?: (action: string) => {};
}): JSX.Element => (
  <div className={`${style.layoutDetails} hidePdfElements`}>
    <span>{`This is the new ${specifier} page layout.`}</span>
    <a
      href={generateBaseUrl('evaluation', 'evaluate')}
      onClick={() => {
        if (trackFn) {
          trackFn('swtich-to-legacy-page');
        }
      }}>
      Click here to return to the old layout
    </a>
  </div>
);
