import { baseURL } from '../../../utils/baseUrl';

import style from './accordionHeader.module.scss';

interface AccordionHeaderI {
  title?: string;
  icon?: JSX.Element;
  name?: string;
  image?: any;
  productUrl?: string | undefined;
  accordionHeaderTitle?: boolean;
  id?: string;
  hasBackground?: boolean;
  ratingSystem?: boolean;
}
const AccordionHeader = ({
  image,
  title,
  icon,
  name,
  productUrl,
  accordionHeaderTitle,
  id,
  hasBackground,
  ratingSystem = false
}: AccordionHeaderI): JSX.Element => (
  <div className={style.accrodionHeader} id={id}>
    <div
      className={`${accordionHeaderTitle ? style.accrodionGrandTitle : style.accrodionHeaderLeft} ${image && style.accrodionHeaderImageLeft}`}>
      <div className={image ? style.accordionHeaderImg : style.accordionHeadericon}>
        {icon && hasBackground ? <div className={style.accordianIcon}>{icon}</div> : icon}
        {image && (
          <img
            src={image}
            alt="product"
            className={ratingSystem ? style.ratingsImage : style.accordionHeaderImage}
          />
        )}
      </div>
      <div className={style.accordianHeaderContent}>
        <div
          className={
            name || accordionHeaderTitle
              ? style.accrodionHeaderTitle
              : style.accrodionHeaderDefaultTitle
          }>
          {title}
        </div>
        <a
          className={`${style.accrodionHeaderName} pdfAccordionHeaderName`}
          href={productUrl ? `${baseURL({ productUrl })}` : ''}>
          {name}
        </a>
      </div>
    </div>
  </div>
);

export default AccordionHeader;
