import { useEffect, useState } from 'react';

declare global {
  interface Window {
    hbspt: {
      forms: {
        create: (options: any) => {
          onFormSubmitted: (callback: () => void) => void;
        };
      };
    };
  }
}

const HubSpotForm = ({
  toggle,
  setIsModalOpen,
  setshowCrossIcon,
  aiSummarydata
}: {
  toggle?: () => void;
  setIsModalOpen: (action: boolean) => void;
  setshowCrossIcon?: (action: boolean) => void;
  aiSummarydata?: any;
}): any => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const script = document.createElement('script');

    if (script) {
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        setLoading(false);

        if (window.hbspt) {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '617005',
            formId: '5cd7805b-7c6e-45fb-a695-4c7d0f753d67',
            target: '#hubspotForm',
            onFormReady: function ($form: any) {
              $form
                .find('input[name="ai_summary"]')
                .val(aiSummarydata?.ai_summary || '')
                .change();
              $form
                .find('input[name="brand_name"]')
                .val(aiSummarydata?.brand_ident || '')
                .change();
              $form
                .find('input[name="sku"]')
                .val(aiSummarydata?.sku || '')
                .change();
              $form
                .find('input[name="site_ident"]')
                .val(aiSummarydata?.site_ident || '')
                .change();
              $form
                .find('input[name="product_page"]')
                .val(aiSummarydata?.product_url || '')
                .change();
              $form.find('input[name="product_category"]').val('Product Category').change();
            },
            onFormSubmitted: () => {
              if (setshowCrossIcon) {
                setshowCrossIcon(false);
              }

              setTimeout(() => {
                setIsModalOpen(false);
              }, 2000);
            },
            onFormError: (error: Error) => {
              console.error('Form submission error:', error);
            }
          });
        }
      });
    }

    return () => {
      script.removeEventListener('load', () => {
        setLoading(false);
      });
    };
  }, [
    toggle,
    setIsModalOpen,
    setshowCrossIcon,
    aiSummarydata?.ai_summary,
    aiSummarydata?.brand_ident,
    aiSummarydata?.sku,
    aiSummarydata?.site_ident,
    aiSummarydata?.product_url
  ]);

  return <div id="hubspotForm">{loading && <p>Loading...</p>}</div>;
};

export default HubSpotForm;
