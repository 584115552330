import { useEffect, useState } from 'react';

export const useScreenWidth = (): number => {
  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions: EventListener = () => {
      const newWidth = window.innerWidth;

      setScreenWidth(newWidth);
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return screenWidth;
};
