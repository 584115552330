export const generateBaseUrl = (searchFor: string, replaceWith: string): string => {
  const origin = window.location.origin;
  let pathname = window.location.pathname;
  const search = window.location.search;

  if (searchFor && replaceWith) {
    pathname = pathname.replace(searchFor, replaceWith);
  }

  return `${origin}${pathname}${search}`;
};
