// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Nqr0uKUBPa_4bahk3vH4{box-shadow:rgba(99,99,99,.2) 0px 2px 8px 0px;padding:1rem 1.5rem;border-radius:12px;background-color:#fff}`, "",{"version":3,"sources":["webpack://./src/components/shared/Box/box.module.scss"],"names":[],"mappings":"AAAA,sBACI,4CAAA,CACA,mBAAA,CACA,kBAAA,CACA,qBAAA","sourcesContent":[".boxContainer {\n    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n    padding: 1rem 1.5rem;\n    border-radius: 12px;\n    background-color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxContainer": `Nqr0uKUBPa_4bahk3vH4`
};
export default ___CSS_LOADER_EXPORT___;
