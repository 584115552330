export const convertFractionToPercentage = (decimalValue: number | undefined): string => {
  if (decimalValue !== undefined) {
    const roundedValue = `${(decimalValue * 100).toFixed(0)}`;
    const decimalPart = parseInt(roundedValue?.split('.')[1]?.charAt(0)) || 0;

    if (decimalPart >= 5) {
      return `${Math.ceil(decimalValue * 100)}%`;
    } else {
      return `${roundedValue}%`;
    }
  } else {
    return '-';
  }
};

export const isBooleanValue = (value: any): any =>
  typeof value === 'object' &&
  value !== null &&
  value.hasOwnProperty('value') &&
  value.value === true
    ? 'Yes'
    : typeof value === 'object' &&
        value !== null &&
        value.hasOwnProperty('value') &&
        value.value === false
      ? 'No'
      : typeof value === 'boolean'
        ? value
          ? 'Yes'
          : 'No'
        : '-';

export const isPercentValue = (value: any): any =>
  typeof value === 'object' && value.value !== undefined
    ? convertFractionToPercentage(value?.value)
    : typeof value === 'object' && value.value === undefined
      ? '-'
      : convertFractionToPercentage(value);

export const extractLenesInfo = (data: any, certifierKey?: string | undefined): any => {
  if (typeof data === 'object') {
    if (typeof data?.value === 'boolean') {
      return {
        type: 'bool',
        value: data?.value
      };
    } else if (Array.isArray(data?.value)) {
      for (const obj of data.value) {
        if (obj.hasOwnProperty(certifierKey)) {
          return {
            type: 'certificate',
            value: data?.value
          };
        }
      }
    }
  } else if (typeof data === 'string') {
    return {
      type: typeof data,
      value: data
    };
  } else if (typeof data === 'number') {
    return {
      type: typeof data,
      value: data
    };
  }
};

export const getCreditsData = (creditDetails: any): { name: any; type: string; value: any }[] =>
  creditDetails?.map((row: any) => ({
    name: row?.name,
    type: extractLenesInfo(row.value, 'certifier')?.type,
    value: extractLenesInfo(row?.value, 'certifier')?.value
  })) ?? [];
