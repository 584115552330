const House = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M20.5153 9.7288L13.0153 2.65255C13.0116 2.64935 13.0082 2.6459 13.005 2.64223C12.7289 2.39111 12.369 2.25195 11.9958 2.25195C11.6225 2.25195 11.2627 2.39111 10.9866 2.64223L10.9763 2.65255L3.48469 9.7288C3.33187 9.86932 3.20989 10.04 3.12646 10.2301C3.04303 10.4202 2.99997 10.6256 3 10.8332V19.5004C3 19.8982 3.15804 20.2797 3.43934 20.561C3.72064 20.8423 4.10218 21.0004 4.5 21.0004H9C9.39782 21.0004 9.77936 20.8423 10.0607 20.561C10.342 20.2797 10.5 19.8982 10.5 19.5004V15.0004H13.5V19.5004C13.5 19.8982 13.658 20.2797 13.9393 20.561C14.2206 20.8423 14.6022 21.0004 15 21.0004H19.5C19.8978 21.0004 20.2794 20.8423 20.5607 20.561C20.842 20.2797 21 19.8982 21 19.5004V10.8332C21 10.6256 20.957 10.4202 20.8735 10.2301C20.7901 10.04 20.6681 9.86932 20.5153 9.7288ZM19.5 19.5004H15V15.0004C15 14.6025 14.842 14.221 14.5607 13.9397C14.2794 13.6584 13.8978 13.5004 13.5 13.5004H10.5C10.1022 13.5004 9.72064 13.6584 9.43934 13.9397C9.15804 14.221 9 14.6025 9 15.0004V19.5004H4.5V10.8332L4.51031 10.8238L12 3.75036L19.4906 10.8219L19.5009 10.8313L19.5 19.5004Z"
      fill="#212529"
    />
  </svg>
);

export default House;
