import { useEffect, useState } from 'react';

export const useTopScrollArrow = (): boolean => {
  const [isDisplayScroller, setDisplayScroller] = useState(false);

  useEffect(() => {
    const handleScroll: EventListener = () => {
      const { scrollTop } = document.documentElement;

      setDisplayScroller(scrollTop > window.innerHeight / 2);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return isDisplayScroller;
};
