import React, { ReactNode } from 'react';

import { ErrorBoundaryWrapper } from './ErrorBoundary';

interface FallbackContainerProps {
  component: string;
  showErrorBoundaryinPDF: Record<string, boolean>;
  children?: ReactNode;
  setShowErrorBoundaryinPDF: (
    updateFn: (prevState: Record<string, boolean>) => Record<string, boolean>
  ) => void;
}

const FallbackContainer: React.FC<FallbackContainerProps> = ({
  component,
  showErrorBoundaryinPDF,
  children,
  setShowErrorBoundaryinPDF
}) => (
  <div className={showErrorBoundaryinPDF[component] ? 'hidePdfElements' : ''}>
    <ErrorBoundaryWrapper
      component={component}
      setShowErrorBoundaryinPDF={setShowErrorBoundaryinPDF}>
      {children}
    </ErrorBoundaryWrapper>
  </div>
);

export default FallbackContainer;
