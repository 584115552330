import React from 'react';

export const PlusIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 3.5C8.13261 3.5 8.25979 3.55268 8.35355 3.64645C8.44732 3.74021 8.5 3.86739 8.5 4V8C8.5 8.13261 8.44732 8.25979 8.35355 8.35355C8.25979 8.44732 8.13261 8.5 8 8.5H4C3.86739 8.5 3.74021 8.44732 3.64645 8.35355C3.55268 8.25979 3.5 8.13261 3.5 8C3.5 7.86739 3.55268 7.74021 3.64645 7.64645C3.74021 7.55268 3.86739 7.5 4 7.5H7.5V4C7.5 3.86739 7.55268 3.74021 7.64645 3.64645C7.74021 3.55268 7.86739 3.5 8 3.5Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5 8C7.5 7.86739 7.55268 7.74021 7.64645 7.64645C7.74021 7.55268 7.86739 7.5 8 7.5H12C12.1326 7.5 12.2598 7.55268 12.3536 7.64645C12.4473 7.74021 12.5 7.86739 12.5 8C12.5 8.13261 12.4473 8.25979 12.3536 8.35355C12.2598 8.44732 12.1326 8.5 12 8.5H8.5V12C8.5 12.1326 8.44732 12.2598 8.35355 12.3536C8.25979 12.4473 8.13261 12.5 8 12.5C7.86739 12.5 7.74021 12.4473 7.64645 12.3536C7.55268 12.2598 7.5 12.1326 7.5 12V8Z"
      fill="black"
    />
  </svg>
);
