export const Cloud = ({
  isCarbonFootPrint = false
}: {
  isCarbonFootPrint?: boolean;
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={isCarbonFootPrint ? '24' : '32'}
    height={isCarbonFootPrint ? '24' : '32'}
    viewBox="0 0 33 33"
    fill="none">
    <g clipPath="url(#clip0_1079_599)">
      <path
        d="M26.6199 14.5283L26.0865 14.5569L26.1055 13.8936C26.1055 13.8554 26.1103 13.8172 26.1103 13.779C26.1103 10.596 23.5192 8 20.3279 8C17.8273 8 15.2811 10.6187 14.5 13C13.8808 11.9931 12.8739 10.4863 11.0686 10.4863C9.21577 10.4863 7.69636 11.9943 7.69636 13.8554C7.69636 13.9031 7.70112 13.9556 7.70112 13.9986L7.73446 14.5187L7.27245 14.5808C4.83853 14.9053 3 17.0002 3 19.4579C3 22.1112 4.53846 24.9745 7.92498 24.9745H26.5961C29.9826 24.9745 31.5211 22.116 31.5211 19.4579C31.5163 16.7473 29.3158 14.5426 26.6056 14.533"
        stroke="black"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1079_599">
        <rect width="32" height="32" fill="white" transform="translate(0.803711 0.155396)" />
      </clipPath>
    </defs>
  </svg>
);
