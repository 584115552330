import { RxCross2 } from 'react-icons/rx';

import styles from './Modal.module.scss';

export const ModalComponent = ({
  isOpen = false,
  modalBody,
  modalHeader,
  modalFooter,
  setIsModalOpen,
  showCrossIcon = false
}: {
  isOpen?: boolean;
  modalHeader?: React.ReactNode;
  modalBody?: React.ReactNode;
  modalFooter?: React.ReactNode;
  setIsModalOpen?: (isOpen: boolean) => void;
  showCrossIcon?: boolean;
}): JSX.Element => (
  <div className={`${isOpen ? styles.overlay : styles.hideModal}`}>
    <div className={styles.customModal}>
      {showCrossIcon && (
        <div
          className={styles.crossIcon}
          onClick={() => {
            if (setIsModalOpen) {
              setIsModalOpen(false);
            }
          }}>
          <RxCross2 fontSize={20} />
        </div>
      )}
      <ModalHeader>{modalHeader}</ModalHeader>
      {modalBody}
      <ModalFooter>{modalFooter}</ModalFooter>
    </div>
  </div>
);

const ModalHeader = ({ children }: { children: any }): JSX.Element => <div>{children}</div>;
const ModalFooter = ({ children }: { children: any }): JSX.Element => <div>{children}</div>;
