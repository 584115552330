import { ReactNode } from 'react';

import style from './projectEvaluation.module.scss';

type PaddingProps = {
  children: ReactNode;
};
export const Padding: React.FC<PaddingProps> = ({ children }) => (
  <div className={style.padding}>{children}</div>
);
export default Padding;
