// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NraIDlfyBX98o6PRXKZw{border:4px solid #f3f3f3;border-top:4px solid var(--global-08-dark);border-radius:50%;animation:GhToaewioiQCQJq3HvWC 1s linear infinite;-webkit-animation:GhToaewioiQCQJq3HvWC 1s linear infinite;padding:.6rem;height:1rem;width:1rem;margin-right:.8rem;-webkit-border-radius:50%;-moz-border-radius:50%;-ms-border-radius:50%;-o-border-radius:50%}@keyframes GhToaewioiQCQJq3HvWC{0%{transform:rotate(0deg)}100%{transform:rotate(360deg);-webkit-transform:rotate(360deg);-moz-transform:rotate(360deg);-ms-transform:rotate(360deg);-o-transform:rotate(360deg)}}`, "",{"version":3,"sources":["webpack://./src/components/shared/Loader/loader.module.scss"],"names":[],"mappings":"AAAA,sBACI,wBAAA,CACA,0CAAA,CACA,iBAAA,CACA,iDAAA,CACA,yDAAA,CACA,aAAA,CACA,WAAA,CACA,UAAA,CACA,kBAAA,CACA,yBAAA,CACA,sBAAA,CACA,qBAAA,CACA,oBAAA,CAGJ,gCACI,GACI,sBAAA,CAGJ,KACI,wBAAA,CACA,gCAAA,CACA,6BAAA,CACA,4BAAA,CACA,2BAAA,CAAA","sourcesContent":[".loader {\n    border: 4px solid #f3f3f3;\n    border-top: 4px solid var(--global-08-dark);\n    border-radius: 50%;\n    animation: spin 1s linear infinite;\n    -webkit-animation: spin 1s linear infinite;\n    padding: 0.6rem;\n    height: 1rem;\n    width: 1rem;\n    margin-right: .8rem;\n    -webkit-border-radius: 50%;\n    -moz-border-radius: 50%;\n    -ms-border-radius: 50%;\n    -o-border-radius: 50%;\n}\n\n@keyframes spin {\n    0% {\n        transform: rotate(0deg);\n    }\n\n    100% {\n        transform: rotate(360deg);\n        -webkit-transform: rotate(360deg);\n        -moz-transform: rotate(360deg);\n        -ms-transform: rotate(360deg);\n        -o-transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `NraIDlfyBX98o6PRXKZw`,
	"spin": `GhToaewioiQCQJq3HvWC`
};
export default ___CSS_LOADER_EXPORT___;
