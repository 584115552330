const TopArrow = ({
  isContributionProject,
  className
}: {
  isContributionProject?: boolean;
  className?: any;
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="14"
    viewBox="0 0 24 14"
    fill="none"
    className={className}>
    <path
      d="M24 11.7223C24.0004 12.011 23.9151 12.2933 23.755 12.5334C23.5948 12.7735 23.3669 12.9607 23.1003 13.0712C22.8336 13.1817 22.5402 13.2106 22.2571 13.1542C21.974 13.0978 21.714 12.9586 21.5101 12.7543L12.0067 3.26885L2.49132 12.7723C2.3558 12.9078 2.19492 13.0153 2.01786 13.0886C1.8408 13.162 1.65103 13.1997 1.45938 13.1997C1.26773 13.1997 1.07796 13.162 0.900899 13.0886C0.723839 13.0153 0.562958 12.9078 0.427442 12.7723C0.291926 12.6367 0.184429 12.4759 0.111089 12.2988C0.0377479 12.1217 1.63387e-07 11.932 1.91374e-07 11.7403C2.19362e-07 11.5487 0.037748 11.3589 0.111089 11.1818C0.184429 11.0048 0.291927 10.8439 0.427442 10.7084L10.5608 0.593013C10.945 0.213072 11.4634 -2.90143e-05 12.0037 -2.89435e-05C12.544 -2.88726e-05 13.0625 0.213073 13.4466 0.593014L23.574 10.6964C23.8442 10.9699 23.997 11.3379 24 11.7223Z"
      fill={isContributionProject ? '#fff' : '#000'}
    />
  </svg>
);

export default TopArrow;
