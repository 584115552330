const WaterSaving = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M16.3125 5.47635C15.1545 4.13895 13.8511 2.93465 12.4266 1.88573C12.3005 1.79739 12.1502 1.75 11.9963 1.75C11.8423 1.75 11.692 1.79739 11.5659 1.88573C10.144 2.93509 8.84317 4.13937 7.6875 5.47635C5.11031 8.43604 3.75 11.556 3.75 14.4998C3.75 16.6878 4.61919 18.7862 6.16637 20.3334C7.71354 21.8806 9.81196 22.7498 12 22.7498C14.188 22.7498 16.2865 21.8806 17.8336 20.3334C19.3808 18.7862 20.25 16.6878 20.25 14.4998C20.25 11.556 18.8897 8.43604 16.3125 5.47635ZM12 21.2498C10.2104 21.2478 8.49466 20.536 7.22922 19.2706C5.96378 18.0051 5.25199 16.2894 5.25 14.4998C5.25 9.13448 10.4503 4.65604 12 3.43729C13.5497 4.65604 18.75 9.1326 18.75 14.4998C18.748 16.2894 18.0362 18.0051 16.7708 19.2706C15.5053 20.536 13.7896 21.2478 12 21.2498ZM17.2397 15.3754C17.0452 16.4617 16.5226 17.4623 15.7422 18.2426C14.9618 19.0228 13.961 19.5452 12.8747 19.7395C12.8334 19.7461 12.7918 19.7495 12.75 19.7498C12.5619 19.7497 12.3806 19.679 12.2422 19.5516C12.1038 19.4241 12.0183 19.2494 12.0028 19.0619C11.9872 18.8744 12.0426 18.6879 12.1581 18.5394C12.2736 18.3909 12.4407 18.2912 12.6262 18.2601C14.1797 17.9985 15.4978 16.6804 15.7612 15.1242C15.7946 14.928 15.9045 14.7531 16.0667 14.6379C16.229 14.5228 16.4304 14.4768 16.6266 14.5101C16.8227 14.5434 16.9976 14.6533 17.1128 14.8156C17.228 14.9779 17.2739 15.1792 17.2406 15.3754H17.2397Z"
      fill="#343A40"
    />
  </svg>
);

export default WaterSaving;
