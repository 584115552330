import { components } from 'react-select';

import { PlusIcon } from '../../../assets/svgs/plusIcon';

import style from './customMenu.module.scss';
export const CustomMenu: any = (props: any) => (
  <components.Menu {...props}>
    <div>
      {props.selectProps.fetchingData ? (
        <span className="fetching">Fetching data...</span>
      ) : (
        <div>{props.children}</div>
      )}
      <div style={{ marginTop: '-0.3rem' }} className={style.customMenuContainer}>
        <p>Create new project</p>
        <PlusIcon />
      </div>
    </div>
  </components.Menu>
);
