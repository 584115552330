import React from 'react';

import style from './productDetails.module.scss';
interface ModalFooterProps {
  onClose?: any;
}
export const ModalFooter: React.FC<ModalFooterProps> = ({ onClose }) => (
  <div className={style.modalFooterAddProjectContainer}>
    <button className={`${style.modalButton} ${style.modalFooterSaveBtn}`}>SUBMIT</button>
    <button
      className={`${style.modalButton} ${style.modalFooterCloseBtn}`}
      onClick={() => onClose()}>
      CLOSE
    </button>
  </div>
);
