import { countOfKeyInObject, sumOfKeyInObject } from './carbonFootPrint';

type GridColumn = {
  heading: string;
  key: string;
  type: string;
  summary?: string;
};

type Grid = {
  [key: string]: {
    columns: GridColumn[];
    summary?: Record<string, unknown>;
  };
};

type GridProducts = {
  [key: string]: [key: string];
};

interface IMapProductToGrids<T extends GridProducts, U extends Grid[]> {
  products: T[];
  grids: U;
}
function mapProductToGrids<T extends GridProducts, U extends Grid[]>(
  product: IMapProductToGrids<T, U>
): Record<string, any> {
  const { products, grids } = product;
  let productList = products;
  const grid: Record<string, Record<string, any>> = {};

  if (productList && typeof productList === 'object' && !Array.isArray(productList)) {
    productList = [productList];
  }

  grids?.forEach((gridItem) => {
    const key = Object.keys(gridItem)[0];
    const gridDetails = gridItem[key];
    const columns = gridDetails.columns.map((col) => col.key);
    const data = productList.map((gridProduct) => {
      const productData = columns.reduce(
        (acc, column) => {
          acc[column] = column === 'qty' ? gridProduct.quantity : gridProduct[column];

          return acc;
        },
        {} as Record<string, any>
      );

      return {
        ...productData,
        thumbnail: gridProduct.thumbnail,
        name: gridProduct.name,
        brand: gridProduct.brand,
        brand_name: gridProduct['brand_name'],
        product_url: gridProduct['product_url']
      };
    });

    grid[key] = {
      ...gridDetails,
      data
    };
  });

  const summary = {
    sku: productList?.length,
    cost: productList.reduce((acc: any, curr: any) => acc + curr.total_cost, 0),
    units: productList.reduce((acc: any, curr: any) => acc + curr.quantity, 0)
  };
  // footer section
  const gridKeys = Object.keys(grid);

  gridKeys.forEach((currentGrid) => {
    const gridItem = grid[currentGrid];

    if (gridItem?.summary?.label === 'Total') {
      const footer = {} as any;

      gridItem.columns.forEach((currentCol: any) => {
        footer[currentCol.key] = null;

        if (currentCol?.summary === 'sum') {
          if (currentCol?.type === 'number') {
            footer[currentCol.key] = sumOfKeyInObject(
              currentCol.key,
              grid[currentGrid].data
            ).toLocaleString();
          }

          if (currentCol?.type === 'percent') {
            footer[currentCol.key] =
              `${(countOfKeyInObject(currentCol.key, grid[currentGrid].data) / grid[currentGrid].data.length) * 100}%`;
          }
        }
      });

      gridItem.footer = footer;
    }
  });

  return {
    grid,
    summary
  };
}

export const isValidObject = (obj: object): boolean => !!Object.keys(obj)?.length;

export default mapProductToGrids;
