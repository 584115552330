const Energy = ({ greenTags = false }: { greenTags?: boolean }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={greenTags ? '24' : '24'}
    height={greenTags ? '24' : '24'}
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M20.2299 11.0786C20.2015 10.9584 20.1438 10.847 20.062 10.7545C19.9803 10.6619 19.8769 10.591 19.7611 10.548L14.3602 8.52203L15.7345 1.64734C15.7657 1.48761 15.744 1.32209 15.6728 1.17576C15.6016 1.02943 15.4847 0.910224 15.3398 0.836138C15.1949 0.762052 15.0299 0.737102 14.8696 0.765053C14.7093 0.793004 14.5624 0.872339 14.4511 0.991087L3.95111 12.2411C3.86583 12.3309 3.80413 12.4405 3.77154 12.5601C3.73895 12.6796 3.73647 12.8053 3.76432 12.926C3.79218 13.0467 3.84951 13.1587 3.93118 13.2518C4.01285 13.345 4.11632 13.4164 4.23236 13.4598L9.63517 15.4858L8.26454 22.353C8.23342 22.5127 8.2551 22.6782 8.3263 22.8245C8.3975 22.9709 8.51436 23.0901 8.65926 23.1642C8.80415 23.2382 8.9692 23.2632 9.12952 23.2352C9.28983 23.2073 9.43671 23.128 9.54798 23.0092L20.048 11.7592C20.1317 11.6693 20.1921 11.5603 20.2238 11.4416C20.2555 11.3229 20.2576 11.1983 20.2299 11.0786ZM10.253 20.0626L11.2345 15.152C11.2697 14.9779 11.2418 14.7969 11.156 14.6413C11.0701 14.4858 10.9319 14.3658 10.7658 14.3026L5.81204 12.4417L13.7452 3.94234L12.7645 8.85296C12.7294 9.02712 12.7572 9.20809 12.8431 9.36364C12.9289 9.5192 13.0672 9.63922 13.2333 9.70234L18.1833 11.5586L10.253 20.0626Z"
      fill="#343A40"
    />
  </svg>
);

export default Energy;
