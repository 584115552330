import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FaExclamationTriangle } from 'react-icons/fa';

import style from './errorboundary.module.scss';
const fallbackRender = ({
  error,
  component,
  setShowErrorBoundaryinPDF
}: {
  error: Error;
  component?: string;
  setShowErrorBoundaryinPDF?: (
    updateFn: (prevState: Record<string, boolean>) => Record<string, boolean>
  ) => void;
}): any => {
  if (component && setShowErrorBoundaryinPDF) {
    setShowErrorBoundaryinPDF((prevState) => {
      if (!prevState[component]) {
        return { ...prevState, [component]: true };
      }

      return prevState;
    });
  }

  console.error('error', new Error('Ecomedes Frontend Error'));

  return (
    <div className={style.fallbackComponent}>
      <FaExclamationTriangle fontSize={35} />
      <p>Something went wrong</p>
      <p>{error.message}</p>
    </div>
  );
};

export const ErrorBoundaryWrapper = ({
  children,
  component,
  setShowErrorBoundaryinPDF
}: {
  children?: React.ReactNode;
  component?: string;
  setShowErrorBoundaryinPDF?: (
    updateFn: (prevState: Record<string, boolean>) => Record<string, boolean>
  ) => void;
}): JSX.Element => (
  <ErrorBoundary
    fallbackRender={(props) => fallbackRender({ ...props, component, setShowErrorBoundaryinPDF })}>
    {children}
  </ErrorBoundary>
);
