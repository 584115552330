import { Cloud } from '../assets/svgs/cloud';
import SkuIcon from '../assets/svgs/sku';
import UnitsIcon from '../assets/svgs/units';

export const carbonFootPrint = (carbonSummaryData: any): any => {
  const unitPercentage = countOfPercentageUnits('weight', 'hp_carbon_per_unit', carbonSummaryData);
  const skuPercentage = Number(
    (countOfKeyInObject('hp_carbon_per_unit', carbonSummaryData) / carbonSummaryData?.length) * 100
  );
  const totalCarbonEmission = Number(sumOfKeyInObject('hp_carbon_number', carbonSummaryData));
  const carbonSummaryArray = [
    {
      icon: <SkuIcon isCarbonFootPrint />,
      qunatity: skuPercentage ? `${skuPercentage?.toFixed(0)}%` : '0%',
      start: 'Percentage of SKUs with Carbon Footprint Data',
      middle: '',
      end: '',
      hasBackground: true
    },
    {
      icon: <UnitsIcon isCarbonFootPrint />,
      qunatity: unitPercentage,
      start: 'Percentage of Units with Carbon Footprint Data',
      middle: '',
      end: '',
      hasBackground: true
    },
    {
      icon: <Cloud isCarbonFootPrint />,
      qunatity: (1 * totalCarbonEmission).toLocaleString(),
      start: 'Total Carbon Emissions (Kg CO2e)',
      middle: '',
      end: '',
      hasBackground: true
    }
  ];

  return carbonSummaryArray;
};

export const sumOfKeyInObject = (key: string, carbonSummaryData: any): number => {
  const value =
    !!carbonSummaryData &&
    carbonSummaryData?.reduce((sum: number, item: any) => {
      if (typeof item[key] === 'number' && !isNaN(item[key])) {
        return (sum += item[key]);
      } else {
        return sum;
      }
    }, 0);

  return value;
};

export const countOfKeyInObject = (key: string, carbonSummaryData: any): number => {
  const value =
    !!carbonSummaryData &&
    carbonSummaryData?.reduce((sum: number, item: any) => {
      if (item[key] != null && !isNaN(item[key] * 1)) {
        return sum + 1;
      } else {
        return sum;
      }
    }, 0);

  return value;
};

export const countOfPercentageUnits = (
  weight: string,
  hpCarbonPerUnit: string,
  carbonSummaryData: any
): any => {
  if (carbonSummaryData.length === 0) {
    return '0%';
  }

  let totalCarbonUnitSum = 0;
  const totalUnitSum = carbonSummaryData?.reduce(
    (totalUnits: number, item: any) => (totalUnits += item?.quantity),
    0
  );

  carbonSummaryData?.map((item: any) => {
    if (item[hpCarbonPerUnit]) {
      totalCarbonUnitSum += item?.quantity;
    }
  });

  return `${((totalCarbonUnitSum / totalUnitSum) * 100).toFixed(0)}%`;
};
