import React, { RefObject, useEffect, useState } from 'react';

import { AddIcon } from '../../assets/svgs/addIcon';
import { DownloadButtonIcon } from '../../assets/svgs/downloadButtonIcon';
import { DownloadPdf } from '../../assets/svgs/downloadPdf';
import { MenuIcon } from '../../assets/svgs/menuIcon';
import WhiteRatings from '../../assets/svgs/whiteRatings';
import { handlePDFDownload } from '../../utils/pdf';
import { generatePageTemplate } from '../ProjectEvaluation/Header';
import AccordionRow from '../shared/AccordionRow/AccordionRow';
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb';
import RCButton from '../shared/Button/Button';
import ButtonContainer from '../shared/ButtonContainer';
import { Container } from '../shared/Container';
import Loader from '../shared/Loader/loader';
import { ModalComponent } from '../shared/Modal/Modal';
import ProductContributionGridHeader from '../shared/ProductContributionGridHeader/ProductContributionGridHeader';

import ModalBody from './modalBody';
import { ModalFooter } from './modalFooter';
import { ModalHeader } from './modalHeader';

import style from './productDetails.module.scss';

interface AccordionData {
  id: number;
  value: boolean;
}

const breadcrumbItems = [
  { href: '/', text: 'Parent' },
  {
    href: '/child',
    text: 'Child'
  }
];
const buttonInfoIcons: { [key: string]: JSX.Element } = {
  plus: <AddIcon />,
  download: <DownloadButtonIcon />,
  certificate: <WhiteRatings />,
  comment: <AddIcon />
};
const menuIcons: { [key: string]: JSX.Element } = {
  plus: <MenuIcon />,
  comment: <MenuIcon />
};

interface ProductDetailProps {
  data: Record<string, any> | undefined;
  pdfRef: RefObject<HTMLDivElement>;
}

export const ProductDetail: React.FC<ProductDetailProps> = ({ data, pdfRef }) => {
  const [accordionContentShow, setaccordionContentShow] = useState<AccordionData[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggle = (): void => setIsModalOpen(!isModalOpen);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const onHeaderClick = (index: number): any => {
    setaccordionContentShow((prevData) =>
      prevData?.map((data) => ({
        ...data,
        value: Number(data.id) === Number(index) ? !data.value : data.value
      }))
    );
  };

  useEffect(() => {
    setaccordionContentShow(
      data?.lens?.map((data: any, index: number) => ({
        id: index,
        value: true
      }))
    );
  }, [data?.lens]);

  return (
    <Container>
      <nav>
        <Breadcrumb navItems={breadcrumbItems} />
      </nav>
      <header>
        <div className={style.productEvaluationSection}>
          <div className={style.productMedaDataSection}>
            <Container>
              <div className={style.productDetails}>
                <div className={style.productImgSection}>
                  <img src={data?.product?.image} alt="product" className={style.productImg} />
                </div>
                <div className={style.productMetaDataContainer}>
                  <div className={style.productMetaData}>
                    <div className={style.productCategory}>
                      {!!data?.product['key-features'] &&
                        data.product['key-features']?.map((feature: any) => (
                          <div className={style.productCategorykeys}>
                            <div className={style.productCategoryInformation}>{feature?.name}:</div>
                            <div className={style.productCategoryValues}>
                              {Array.isArray(feature?.value) ? (
                                <div>{feature.value.join(', ')}</div>
                              ) : (
                                <div>{feature?.value}</div>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className={style.productBrandName}>
                      {data?.product['brand-name'] || ''}
                    </div>
                    <div className={style.productName}>{data?.product?.name || ''}</div>
                    <div className={style.productDescription}>
                      {data?.product?.description || ''}
                    </div>
                  </div>
                  <div className={style.downloadButtons}>
                    <RCButton
                      onClick={() => {
                        handlePDFDownload(
                          pdfRef,
                          setIsPdfLoading,
                          generatePageTemplate,
                          `${data?.product['brand-name']?.toLowerCase()}-${data?.product.sku}-ecomedes-evaluation`
                        );
                      }}
                      className={`${style.pdfButton} ${style.downloadButtonContent} hidePdfElements`}
                      variant="primary">
                      <span className={style.downloadPdfText}>DOWNLOAD PDF</span>
                      {isPdfLoading ? <Loader /> : <DownloadPdf productEval />}
                    </RCButton>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </header>
      <div className={style.actionButtonContainer}>
        <Container>
          <div className={style.actionButtonsSection}>
            {data &&
              data['action-buttons']?.map((info: any) => (
                <ButtonContainer
                  Icon={buttonInfoIcons[info.icon] ?? ''}
                  text={info.text ?? ''}
                  MenuIcon={menuIcons[info.icon] ?? ''}
                  toggle={toggle}
                />
              ))}
          </div>
        </Container>
      </div>

      <main>
        <div className={style.ratingSystem}>
          <Container>
            <h3 className={style.ratingSystemHeader}>Details</h3>

            {!!data?.lens &&
              data?.lens?.map((lensValue: any, lensIndex: number): any => (
                <div style={{ marginBottom: '2rem' }}>
                  <div key={`lens-${lensIndex}`}>
                    <ProductContributionGridHeader
                      key={`header-${lensIndex}`}
                      name={lensValue?.name || ''}
                      image={lensValue?.image || ''}
                      notes={lensValue?.notes || ''}
                      lensDetails={lensValue?.attributes || []}
                      productPage
                      onHeaderClick={onHeaderClick}
                      index={lensIndex}
                      accordionContentShow={accordionContentShow}
                    />

                    <div className={style.productTable}>
                      <div
                        className={`${
                          accordionContentShow[lensIndex]?.value
                            ? `${style.contentShow} ${style.transition}`
                            : `${style.contentHide} ${style.transition}`
                        } pdfContentShow`}>
                        <div>
                          {lensValue?.attributes?.map((detailsData: any, detailsIndex: number) => (
                            <AccordionRow
                              key={`row-${detailsData}-${detailsIndex}`}
                              title={detailsData?.name || ''}
                              value={detailsData?.value || ''}
                              type={detailsData?.kind || ''}
                              tooltipText={detailsData?.hint || ''}
                              isToolTip
                              isLensData
                              isLightContent
                            />
                          ))}
                          <div
                            onClick={() => onHeaderClick(lensIndex)}
                            className={`${style.accordionFooter} pdfContentHide`}>
                            {accordionContentShow[lensIndex]?.value ? 'SHOW LESS ' : 'SHOW MORE'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Container>
        </div>
      </main>
      <ModalComponent
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalHeader={<ModalHeader />}
        modalBody={<ModalBody />}
        modalFooter={<ModalFooter onClose={toggle} />}
      />
    </Container>
  );
};
