import style from './tooltip.module.scss';
const ToolTip = ({
  children,
  tooltipText = 'Tooltip'
}: {
  children: React.ReactNode;
  tooltipText?: string;
}): JSX.Element => (
  <div className={style.tooltip}>
    {children}
    <span className={style.tooltiptext}>{tooltipText}</span>
  </div>
);

export default ToolTip;
