import { Form, FormGroup, Input, Label } from 'reactstrap';

import style from './sustainabilitySummary.module.scss';
export const ModalBody = ({
  pdfList,
  toggleCheckBox
}: {
  pdfList: any;
  toggleCheckBox?: any;
}): JSX.Element => (
  <div className={style.modalBody}>
    <Form>
      {pdfList?.map((el: any) => (
        <FormGroup check inline className={style.modalBodyFormGroup} key={el.key}>
          <Input
            style={{ accentColor: 'black' }}
            onChange={() => {
              toggleCheckBox(el.key);
            }}
            type="checkbox"
            checked={!el.value}
          />
          <Label check className={style.modalCheckboxLabel} onClick={() => toggleCheckBox(el.key)}>
            {el.key}
          </Label>
        </FormGroup>
      ))}
    </Form>
  </div>
);
