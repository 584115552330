export const HouseField = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <rect width="40" height="40" rx="8" fill="#F2F2F2" />
    <circle cx="20" cy="20" r="18" fill="#343A40" />
    <path d="M17.9821 30.9838L17.9688 25.0027L21.9598 24.9983V30.9838H17.9821Z" fill="#F2F2F2" />
    <path
      d="M22.9585 30.9838V23.9999L16.9719 24.0044L16.9852 30.9838H10.9941V19.8105L19.9652 13.1921L28.9318 19.8105V30.9838H22.9585Z"
      fill="#F2F2F2"
    />
    <path
      d="M30.9889 22.7833V21.785H33.9688V14.2708L28.7751 10.2908L24.1737 13.8162L23.3452 13.1922L28.7751 9.03398L34.971 13.7805V22.7922H30.9933L30.9889 22.7833ZM29.9287 31.9822H9.99555V22.7833H9.98664V21.785H9.99555V19.307L19.9644 11.9532L29.9332 19.307V31.9866H29.9287V31.9822ZM8.98441 22.7833H4.93541V13.7716L11.1314 9.02507L16.5212 13.1565L15.6704 13.7627L11.1314 10.2819L5.93764 14.2618V21.776H8.98886V22.7833H8.98441ZM20 0C8.95323 0 0 8.95376 0 19.9978C0 31.0418 8.95323 40 20 40C31.0468 40 40 31.0462 40 19.9978C40 8.9493 31.0423 0 20 0Z"
      fill="#F2F2F2"
    />
  </svg>
);
