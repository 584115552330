import { useEffect, useRef, useState } from 'react';

import style from './greenTags.module.scss';

const GreenTags = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkOverflow = (): void => {
      if (sectionRef.current) {
        const section = sectionRef.current;

        setIsOverflowing(section.scrollHeight > section.clientHeight);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [children]);

  const [isExpanded, setIsExpanded] = useState(false);
  const handleShowMore = (): void => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className={style.greentagContainer}>
      <div
        ref={sectionRef}
        className={style.badgeSection}
        style={{
          overflow: isExpanded ? 'visible' : 'hidden',
          maxHeight: isExpanded ? 'none' : '50px',
          transition: 'max-height 0.3s ease'
        }}>
        {children}
      </div>

      {isOverflowing && (
        <div className={style.expandContainer} onClick={handleShowMore}>
          <p className={style.expandTerms}>{isExpanded ? 'Show Less' : 'Show More'}</p>
        </div>
      )}
    </div>
  );
};

export default GreenTags;
