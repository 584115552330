const ManufactureLocation = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M21.4361 9.38403L4.94271 3.26497H4.93053C4.69825 3.18492 4.44816 3.17169 4.20873 3.22676C3.9693 3.28183 3.75013 3.403 3.57616 3.57648C3.40219 3.74996 3.2804 3.96879 3.22466 4.20806C3.16892 4.44734 3.18145 4.69746 3.26084 4.92997C3.26084 4.92997 3.26084 4.9384 3.26084 4.94215L9.38459 21.4356C9.4719 21.6933 9.6384 21.9168 9.8603 22.0742C10.0822 22.2316 10.3482 22.3149 10.6202 22.3122H10.6446C10.9221 22.3103 11.1917 22.2193 11.4135 22.0525C11.6353 21.8857 11.7976 21.652 11.8765 21.3859L14.0852 14.2084C14.0942 14.1793 14.1102 14.1528 14.1318 14.1312C14.1533 14.1096 14.1798 14.0937 14.209 14.0847L21.3865 11.8759C21.6502 11.7945 21.8816 11.632 22.0477 11.4116C22.2138 11.1911 22.3062 10.9239 22.3117 10.6479C22.3172 10.3719 22.2356 10.1013 22.0784 9.87435C21.9212 9.64744 21.6965 9.47587 21.4361 9.38403ZM21.0555 10.8006L13.8752 13.0093C13.6707 13.0723 13.4847 13.1843 13.3334 13.3356C13.1821 13.4869 13.0701 13.673 13.0071 13.8775L10.7983 21.055C10.7887 21.0939 10.7658 21.1283 10.7337 21.1524C10.7016 21.1765 10.6622 21.1888 10.6221 21.1872C10.582 21.1907 10.5419 21.1799 10.509 21.1568C10.476 21.1336 10.4523 21.0995 10.4421 21.0606C10.4425 21.0568 10.4425 21.0531 10.4421 21.0493L4.32303 4.55778C4.31267 4.52505 4.31151 4.4901 4.31965 4.45675C4.3278 4.4234 4.34494 4.39293 4.36922 4.36865C4.39349 4.34438 4.42396 4.32724 4.45731 4.31909C4.49066 4.31095 4.52561 4.31211 4.55834 4.32247L21.0499 10.4406H21.0611C21.0985 10.4535 21.1308 10.4779 21.1534 10.5102C21.1761 10.5426 21.188 10.5813 21.1873 10.6208C21.1867 10.6602 21.1736 10.6985 21.15 10.7302C21.1263 10.7618 21.0932 10.7851 21.0555 10.7968V10.8006Z"
      fill="black"
    />
  </svg>
);

export default ManufactureLocation;
