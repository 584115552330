/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useState } from 'react';

import RCButton from '../shared/Button/Button';

import BackIcon from './../../assets/svgs/backArrow';
import Padding from './Padding';

import style from './sustainabilitySummary.module.scss';
// eslint-disable-next-line @typescript-eslint/ban-types
const Navbar = ({
  backToProject,
  projectName
}: {
  backToProject: () => object;
  projectName?: any;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const [isButtonHovered, setIsButonHoverd] = useState(false);

  return (
    <Padding>
      <>
        <div className={`${style.notVisibleLogo} displayPdfBrandLogo`} onClick={backToProject}>
          <img
            className="pdfBrandLogo"
            src={projectName?.pdf_logo || projectName?.ecomedes_logo}
            alt="logo"
          />
        </div>

        <RCButton
          onClick={backToProject}
          className={`${style.navbar} hidePdfElements`}
          onMouseEnter={() => setIsButonHoverd(true)}
          onMouseLeave={() => setIsButonHoverd(false)}>
          <div className={style.backToProjectIcon}>
            <BackIcon isButtonHovered={isButtonHovered} />
          </div>
          <span className={style.navTitle}>Back to project</span>
        </RCButton>
      </>
    </Padding>
  );
};

export default Navbar;
