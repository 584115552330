import { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CSVIcon from '../../assets/svgs/csvIcon';
import { DownloadPdf } from '../../assets/svgs/downloadPdf';
import { handlePDFDownload } from '../../utils/pdf';
import { pdfStyle } from '../../utils/pdf.styles';
import RCButton from '../shared/Button/Button';
import Loader from '../shared/Loader/loader';

import Padding from './Padding';

import style from './projectEvaluation.module.scss';

export interface PageTemplateProps {
  pageNum: number;
  totalPages: number;
}

export const generatePageTemplate = (
  { pageNum, totalPages }: PageTemplateProps,
  url: string
): JSX.Element => (
  <div
    style={{
      position: 'absolute',
      bottom: '0px',
      left: '0',
      width: '100%'
    }}>
    <style>{pdfStyle()}</style>
    <span style={{ position: 'absolute', bottom: '60px', left: '45%', width: '100%' }}>
      Page {pageNum} of {totalPages}
    </span>
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: '49px',
        backgroundColor: '#F2F2F2',
        padding: '12px 0px',
        position: 'absolute',
        bottom: '2px',
        left: '0'
      }}>
      <div
        style={{
          fontSize: '20px',
          fontWeight: 900,
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          marginTop: '0rem',
          marginRight: '.5rem'
        }}>
        Powered by
      </div>
      <img
        src={url}
        alt="ecomedes"
        style={{ marginTop: '5px', width: '131.538px', height: '24px' }}
      />
    </div>
  </div>
);

const Header = ({
  pdfRef,
  name,
  trackFn,
  projectName,
  address,
  downloadCSV
}: {
  pdfRef: RefObject<HTMLDivElement>;
  name: string | null;
  projectName: any;
  address: string | null;
  trackFn: (action: string) => {};
  downloadCSV?: any;
}): JSX.Element => {
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isPDFButtonFocused, setIsPDFButtonFocused] = useState(false);
  const [isCSVButtonFocused, setIsCSVButtonFocused] = useState(false);
  const { t } = useTranslation();

  return (
    <Padding>
      <header className={`${style.header} projectEvalHeaderSection`}>
        <div className={`${style.projectTitle} projectTitlepdf`}>{name}</div>
        <div className={style.headerbuttonSection}>
          <div
            className={style.downloadButtons}
            onMouseOver={() => setIsCSVButtonFocused(true)}
            onFocus={() => setIsCSVButtonFocused(true)}
            onMouseLeave={() => setIsCSVButtonFocused(false)}>
            <RCButton
              onClick={() => {
                downloadCSV((error: any) => console.log(error));
                trackFn('download-csv');
              }}
              className={`${style.downloadButtonContent} hidePdfElements`}
              variant="primary">
              <CSVIcon isButtonFocused={isCSVButtonFocused} />
              <span className={style.downloadPdfText}>{t('projectEval.header.downloadCSV')}</span>
            </RCButton>
          </div>
          <div
            className={style.downloadButtons}
            onMouseOver={() => setIsPDFButtonFocused(true)}
            onFocus={() => setIsPDFButtonFocused(true)}
            onMouseLeave={() => setIsPDFButtonFocused(false)}>
            <RCButton
              onClick={() => {
                handlePDFDownload(
                  pdfRef,
                  setIsPdfLoading,
                  generatePageTemplate,
                  name,
                  projectName,
                  address
                );
                trackFn('download-pdf');
              }}
              className={`${style.downloadButtonContent} hidePdfElements`}
              variant="primary">
              {isPdfLoading ? <Loader /> : <DownloadPdf isButtonFocused={isPDFButtonFocused} />}
              <span className={style.downloadPdfText}>{t('projectEval.header.downloadPDF')}</span>
            </RCButton>
          </div>
        </div>
      </header>
    </Padding>
  );
};

export default Header;
