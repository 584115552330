export const Car = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <rect width="40" height="40" rx="8" fill="#F2F2F2" />
    <circle cx="20" cy="21" r="14" fill="#343A40" />
    <path
      d="M23.2931 13.8971H19.6979C19.0188 13.8971 18.3841 14.1945 17.9491 14.7138L15.792 17.2881H23.2975V13.8971H23.2931Z"
      fill="#F2F2F2"
    />
    <path
      d="M31.3004 17.2704L29.9245 14.119C29.849 13.9814 29.7025 13.8971 29.5427 13.8971H23.9502V17.2881H31.3093L31.3004 17.2704Z"
      fill="#F2F2F2"
    />
    <path
      d="M20 0C8.95251 0 0 8.95251 0 20C0 31.0475 8.95251 40 20 40C31.0475 40 40 31.0475 40 20C40 8.95251 31.0431 0 20 0ZM13.3555 26.7643C12.1261 26.7643 11.1318 25.7701 11.1318 24.5406C11.1318 23.3111 12.1261 22.3169 13.3555 22.3169C14.585 22.3169 15.5792 23.3111 15.5792 24.5406C15.5792 25.7701 14.585 26.7643 13.3555 26.7643ZM26.7421 26.7643C25.5126 26.7643 24.5184 25.7701 24.5184 24.5406C24.5184 23.3111 25.5126 22.3169 26.7421 22.3169C27.9716 22.3169 28.9658 23.3111 28.9658 24.5406C28.9658 25.7701 27.9716 26.7643 26.7421 26.7643ZM31.984 17.4478V24.8824L29.4363 24.8957C29.4496 24.7803 29.4629 24.6605 29.4629 24.5406C29.4629 23.0226 28.2335 21.7887 26.7111 21.7887C25.1886 21.7887 23.9592 23.0182 23.9592 24.5406C23.9592 24.6605 23.968 24.7803 23.9858 24.8957H16.0497C16.063 24.7803 16.0763 24.6605 16.0763 24.5406C16.0763 23.0226 14.8469 21.7887 13.3245 21.7887C11.802 21.7887 10.5726 23.0182 10.5726 24.5406C10.5726 24.6605 10.5814 24.7803 10.5992 24.8957H8.01154V22.6276C8.01154 18.2201 11.9751 17.3103 14.9134 17.2881L17.4345 14.2832C17.9938 13.613 18.8238 13.2268 19.6937 13.2268H29.5384C29.9512 13.2268 30.3285 13.4576 30.5193 13.826L31.9041 16.9951C31.9174 17.0173 31.9263 17.0439 31.9352 17.0661C31.9485 17.1016 31.9574 17.1371 31.9618 17.1727C31.9663 17.2082 31.9707 17.2481 31.9751 17.2836C31.9751 17.3236 31.9751 17.368 31.9751 17.4079V17.4478H31.984Z"
      fill="#F2F2F2"
    />
    <path
      d="M26.7381 22.9916C25.8814 22.9916 25.1846 23.6884 25.1846 24.5451C25.1846 25.4017 25.8814 26.0985 26.7381 26.0985C27.5947 26.0985 28.2915 25.4017 28.2915 24.5451C28.2915 23.6884 27.5947 22.9916 26.7381 22.9916ZM26.7381 25.5792C26.1655 25.5792 25.6994 25.1132 25.6994 24.5406C25.6994 23.9681 26.1655 23.502 26.7381 23.502C27.3106 23.502 27.7767 23.9681 27.7767 24.5406C27.7767 25.1132 27.3106 25.5792 26.7381 25.5792Z"
      fill="#F2F2F2"
    />
    <path
      d="M8.68164 22.6276V24.2299H9.92443C10.0842 22.49 11.5489 21.1274 13.3288 21.1274C15.1086 21.1274 16.5733 22.4945 16.7331 24.2299H23.3155C23.4752 22.49 24.94 21.1274 26.7198 21.1274C28.4997 21.1274 29.9644 22.4945 30.1242 24.2299H31.327V17.9539H15.0243C9.78683 17.9539 8.69052 20.4971 8.69052 22.6276H8.68164ZM21.7975 18.988H22.4988C22.7163 18.988 22.8938 19.1656 22.8938 19.3831C22.8938 19.6005 22.7163 19.7781 22.4988 19.7781H21.7975C21.58 19.7781 21.4025 19.6005 21.4025 19.3831C21.4025 19.1656 21.58 18.988 21.7975 18.988Z"
      fill="#F2F2F2"
    />
    <path
      d="M13.3552 22.9916C12.4986 22.9916 11.8018 23.6884 11.8018 24.5451C11.8018 25.4017 12.4986 26.0985 13.3552 26.0985C14.2119 26.0985 14.9087 25.4017 14.9087 24.5451C14.9087 23.6884 14.2119 22.9916 13.3552 22.9916ZM13.3552 25.5792C12.7827 25.5792 12.3166 25.1132 12.3166 24.5406C12.3166 23.9681 12.7827 23.502 13.3552 23.502C13.9278 23.502 14.3939 23.9681 14.3939 24.5406C14.3939 25.1132 13.9278 25.5792 13.3552 25.5792Z"
      fill="#F2F2F2"
    />
  </svg>
);
