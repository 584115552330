const BackArrow = ({ isButtonHovered }: { isButtonHovered: boolean }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_645_177)">
      <path
        d="M14.6667 6.66648H4.32L8.69333 2.23981C9.22667 1.70648 9.22667 0.853144 8.69333 0.373145C8.16 -0.160189 7.30667 -0.160189 6.82667 0.373145L0.533333 6.71981C0.213333 7.09314 0 7.51981 0 7.99981C0 8.47981 0.213333 8.95981 0.533333 9.27981L6.82667 15.5731C7.09333 15.8398 7.41333 15.9465 7.78667 15.9465C8.16 15.9465 8.48 15.8398 8.74667 15.5731C9.28 15.0398 9.28 14.1865 8.74667 13.7065L4.37333 9.33314H14.6667C15.4133 9.33314 16 8.74648 16 7.99981C16 7.25314 15.4133 6.66648 14.6667 6.66648Z"
        fill={isButtonHovered ? 'white' : '#212529'}
      />
    </g>
    <defs>
      <clipPath id="clip0_645_177">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BackArrow;
