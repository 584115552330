import style from './buttonContainer.module.scss';
interface ButtonContainerProps {
  Icon?: any;
  text?: string;
  MenuIcon?: any;
  toggle?: any;
}
const ButtonContainer: React.FC<ButtonContainerProps> = ({
  Icon,
  text = 'label',
  MenuIcon,
  toggle
}) => (
  <div data-testid="button-container" className={style.container} onClick={toggle}>
    <div className={style.buttonInfo}>
      <div className={style.iconContainer}>{Icon}</div>
      <h3 className={style.buttonLabel}>{text}</h3>
    </div>
    {MenuIcon}
  </div>
);

export default ButtonContainer;
