import React from 'react';

const Medal: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.25 6H16.5V5.25C16.5 4.65326 16.2629 4.08097 15.841 3.65901C15.419 3.23705 14.8467 3 14.25 3H9.75C9.15326 3 8.58097 3.23705 8.15901 3.65901C7.73705 4.08097 7.5 4.65326 7.5 5.25V6H3.75C3.35218 6 2.97064 6.15804 2.68934 6.43934C2.40804 6.72064 2.25 7.10218 2.25 7.5V19.5C2.25 19.8978 2.40804 20.2794 2.68934 20.5607C2.97064 20.842 3.35218 21 3.75 21H20.25C20.6478 21 21.0294 20.842 21.3107 20.5607C21.592 20.2794 21.75 19.8978 21.75 19.5V7.5C21.75 7.10218 21.592 6.72064 21.3107 6.43934C21.0294 6.15804 20.6478 6 20.25 6ZM9 5.25C9 5.05109 9.07902 4.86032 9.21967 4.71967C9.36032 4.57902 9.55109 4.5 9.75 4.5H14.25C14.4489 4.5 14.6397 4.57902 14.7803 4.71967C14.921 4.86032 15 5.05109 15 5.25V6H9V5.25ZM20.25 19.5H3.75V7.5H20.25V19.5ZM15 13.5C15 13.6989 14.921 13.8897 14.7803 14.0303C14.6397 14.171 14.4489 14.25 14.25 14.25H12.75V15.75C12.75 15.9489 12.671 16.1397 12.5303 16.2803C12.3897 16.421 12.1989 16.5 12 16.5C11.8011 16.5 11.6103 16.421 11.4697 16.2803C11.329 16.1397 11.25 15.9489 11.25 15.75V14.25H9.75C9.55109 14.25 9.36032 14.171 9.21967 14.0303C9.07902 13.8897 9 13.6989 9 13.5C9 13.3011 9.07902 13.1103 9.21967 12.9697C9.36032 12.829 9.55109 12.75 9.75 12.75H11.25V11.25C11.25 11.0511 11.329 10.8603 11.4697 10.7197C11.6103 10.579 11.8011 10.5 12 10.5C12.1989 10.5 12.3897 10.579 12.5303 10.7197C12.671 10.8603 12.75 11.0511 12.75 11.25V12.75H14.25C14.4489 12.75 14.6397 12.829 14.7803 12.9697C14.921 13.1103 15 13.3011 15 13.5Z"
      fill="black"
    />
  </svg>
);

export default Medal;
