/* eslint-disable @typescript-eslint/naming-convention */
export const EvlProject = {
  project: {
    name: 'Ecomedes Office Upgrade',
    description: 'Office upgrade for a startup',
    client_name: 'Acme',
    size: 5000,
    address: '4143 NE 142nd St.',
    postal_code: '98125',
    notes: 'no notes'
  },
  products: [
    {
      quantity: 250,
      total_cost: 1231
    },
    {
      quantity: 250,
      total_cost: 1231
    },
    {
      quantity: 250,
      total_cost: 1231
    },
    {
      quantity: 250,
      total_cost: 1231
    }
  ],
  nonContributionProject: [
    {
      gridTitle: 'WELL v2™ pilot Building Standard™',
      key: 'pilot_building_standard',
      data: [
        {
          title: 'Monthly Gallons Saved',
          value: 62093,
          type: 'number'
        },
        {
          title: 'Yearly, Gallons Saved',
          value: 912,
          type: 'currency'
        },
        {
          title: 'Weekly, Gallons Saved',
          value: 'Contributes',
          type: 'string'
        },
        {
          title: 'Manufacturing Location',
          value: true,
          type: 'boolean'
        },
        {
          title: 'Manufacturing Location',
          value: false,
          type: 'boolean'
        },
        {
          title: 'Download Link',
          value: 'https://products.ecomedesstaging.com/impact-summary/17305',
          type: 'downloadLink'
        },
        {
          title: 'ShareLink',
          value: 'https://products.ecomedesstaging.com/impact-summary/17305',
          type: 'shareLink'
        },
        {
          title: 'Monthly Gallons Saved',
          value: 4567,
          type: 'number',
          showIcon: true
        },
        {
          title: 'Weekly, Gallons Saved',
          value: 'Contributes',
          type: 'string',
          showIcon: true
        }
      ]
    },
    {
      gridTitle: 'LEED®v4 BD+C: Hospitality',
      key: 'leed_hospitality',
      data: [
        {
          title: 'Monthly Gallons Saved',
          value: 62093,
          type: 'number'
        },
        {
          title: 'Yearly, Gallons Saved',
          value: 912,
          type: 'currency'
        },
        {
          title: 'Weekly, Gallons Saved',
          value: 'Contributes',
          type: 'string'
        },
        {
          title: 'Manufacturing Location',
          value: true,
          type: 'boolean'
        },
        {
          title: 'Manufacturing Location',
          value: false,
          type: 'boolean'
        },
        {
          title: 'Download Link',
          value: 'https://products.ecomedesstaging.com/impact-summary/17305',
          type: 'downloadLink'
        },
        {
          title: 'ShareLink',
          value: 'https://products.ecomedesstaging.com/impact-summary/17305',
          type: 'shareLink'
        },
        {
          title: 'Monthly Gallons Saved',
          value: 4567,
          type: 'number',
          showIcon: true
        },
        {
          title: 'Weekly, Gallons Saved',
          value: 'Contributes',
          type: 'string',
          showIcon: true
        }
      ]
    },
    {
      gridTitle: 'LEED®v4 BD+C: Data Centers',
      key: 'leed_datacenters',
      data: [
        {
          title: 'Monthly Gallons Saved',
          value: 62093,
          type: 'number'
        },
        {
          title: 'Yearly, Gallons Saved',
          value: 912,
          type: 'currency'
        },
        {
          title: 'Weekly, Gallons Saved',
          value: 'Contributes',
          type: 'string'
        },
        {
          title: 'Manufacturing Location',
          value: true,
          type: 'boolean'
        },
        {
          title: 'Manufacturing Location',
          value: false,
          type: 'boolean'
        },
        {
          title: 'Download Link',
          value: 'https://products.ecomedesstaging.com/impact-summary/17305',
          type: 'downloadLink'
        },
        {
          title: 'ShareLink',
          value: 'https://products.ecomedesstaging.com/impact-summary/17305',
          type: 'shareLink'
        },
        {
          title: 'Monthly Gallons Saved',
          value: 4567,
          type: 'number',
          showIcon: true
        },
        {
          title: 'Weekly, Gallons Saved',
          value: 'Contributes',
          type: 'string',
          showIcon: true
        }
      ]
    },
    {
      gridTitle: 'Overall Savings',
      key: 'overall_savings',
      footerContent: 'Values based on project parameters',
      data: [
        {
          title: 'Yearly, Gallons Saved',
          value: 106658,
          type: 'currency'
        }
      ]
    },
    {
      gridTitle: 'Water savings',
      key: 'water_savings',
      footerContent: 'Values based on project parameters',
      data: [
        {
          title: 'Monthly Gallons Saved',
          value: 62093,
          type: 'number'
        },
        {
          title: 'Yearly, Gallons Saved',
          value: 912,
          type: 'currency'
        },
        {
          title: 'Weekly, Gallons Saved',
          value: 'Contributes',
          type: 'string'
        },
        {
          title: 'Manufacturing Location',
          value: true,
          type: 'boolean'
        },
        {
          title: 'Manufacturing Location',
          value: false,
          type: 'boolean'
        },
        {
          title: 'Download Link',
          value: 'https://products.ecomedesstaging.com/impact-summary/17305',
          type: 'downloadLink'
        },
        {
          title: 'ShareLink',
          value: 'https://products.ecomedesstaging.com/impact-summary/17305',
          type: 'shareLink'
        },
        {
          title: 'Monthly Gallons Saved',
          value: 4567,
          type: 'number',
          showIcon: true
        },
        {
          title: 'Weekly, Gallons Saved',
          value: 'Contributes',
          type: 'string',
          showIcon: true
        }
      ]
    },
    {
      gridTitle: 'Energy Impact Calculator',
      key: 'energy_impact_calculator',
      footerContent: 'Values based on project parameters',
      data: [
        {
          title: 'Monthly Gallons Saved',
          value: 62093,
          type: 'number'
        },
        {
          title: 'Yearly, Gallons Saved',
          value: 912,
          type: 'currency'
        },
        {
          title: 'Weekly, Gallons Saved',
          value: 'Contributes',
          type: 'string'
        },
        {
          title: 'Manufacturing Location',
          value: true,
          type: 'boolean'
        },
        {
          title: 'Manufacturing Location',
          value: false,
          type: 'boolean'
        },
        {
          title: 'Download Link',
          value: 'https://products.ecomedesstaging.com/impact-summary/17305',
          type: 'downloadLink'
        },
        {
          title: 'ShareLink',
          value: 'https://products.ecomedesstaging.com/impact-summary/17305',
          type: 'shareLink'
        },
        {
          title: 'Monthly Gallons Saved',
          value: 4567,
          type: 'number',
          showIcon: true
        },
        {
          title: 'Weekly, Gallons Saved',
          value: 'Contributes',
          type: 'string',
          showIcon: true
        }
      ]
    }
  ],
  contributionProject: [
    {
      thumbnail:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS49pwuPgji8IHwxw3A5KsytmlgYhIEoOOyBHaiHNgRNg&s',
      brandName: 'American Standard',
      name: 'HydroFocus® 2.0 gpm/7.6 L/min 4-1/2-Inch 5-Function Hand Shower/1660207.002',
      productUrl: '',
      data: [
        {
          title: 'WELL v2™ pilot Building Standard™',
          key: 'pilot_building_standard',
          data: [
            {
              title: 'Monthly Gallons Saved',
              value: 62093,
              type: 'number'
            },
            {
              title: 'Yearly, Gallons Saved',
              value: 912,
              type: 'currency'
            },
            {
              title: 'Weekly, Gallons Saved',
              value: 'Contributes',
              type: 'string'
            },
            {
              title: 'Manufacturing Location',
              value: true,
              type: 'boolean'
            },
            {
              title: 'Manufacturing Location',
              value: false,
              type: 'boolean'
            },
            {
              title: 'Download Link',
              value: 'https://products.ecomedesstaging.com/impact-summary/17305',
              type: 'downloadLink'
            },
            {
              title: 'ShareLink',
              value: 'https://products.ecomedesstaging.com/impact-summary/17305',
              type: 'shareLink'
            },
            {
              title: 'Monthly Gallons Saved',
              value: 4567,
              type: 'number',
              showIcon: true
            },
            {
              title: 'Weekly, Gallons Saved',
              value: 'Contributes',
              type: 'string',
              showIcon: true
            }
          ]
        },
        {
          title: 'LEED®v4 BD+C: Hospitality',
          key: 'leed_datacenters',
          message:
            'The recycled content data from the manufacturing plant closest to the postal code setting is displayed first and used for calculating credit contributions. Changing the postal code setting at the top of the page will re-evaluate which manufacturing plant is closest.',
          data: [
            {
              title: 'Monthly Gallons Saved',
              value: 62093,
              type: 'number'
            },
            {
              title: 'Yearly, Gallons Saved',
              value: 912,
              type: 'currency'
            },
            {
              title: 'Weekly, Gallons Saved',
              value: 'Contributes',
              type: 'string',
              row_bg_gray: true
            },
            {
              title: 'Manufacturing Location',
              value: true,
              type: 'boolean'
            },
            {
              title: 'Manufacturing Location',
              value: false,
              type: 'boolean'
            },
            {
              title: 'Download Link',
              value: 'https://products.ecomedesstaging.com/impact-summary/17305',
              type: 'downloadLink',
              row_bg_gray: true
            },
            {
              title: 'ShareLink',
              value: 'https://products.ecomedesstaging.com/impact-summary/17305',
              type: 'shareLink'
            },
            {
              title: 'Monthly Gallons Saved',
              value: 4567,
              type: 'number',
              showIcon: true,
              row_bg_gray: true
            },
            {
              title: 'Weekly, Gallons Saved',
              value: 'Contributes',
              type: 'string',
              showIcon: true
            }
          ]
        }
      ]
    },
    {
      thumbnail:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS49pwuPgji8IHwxw3A5KsytmlgYhIEoOOyBHaiHNgRNg&s',
      brandName: 'American Standard',
      name: 'HydroFocus® 2.0 gpm/7.6 L/min 4-1/2-Inch 5-Function Hand Shower/1660207.002',
      productUrl: '',
      data: [
        {
          title: 'WELL v2™ pilot Building Standard™',
          key: 'pilot_building_standard',
          data: [
            {
              title: 'Monthly Gallons Saved',
              value: 62093,
              type: 'number'
            },
            {
              title: 'Yearly, Gallons Saved',
              value: 912,
              type: 'currency'
            },
            {
              title: 'Weekly, Gallons Saved',
              value: 'Contributes',
              type: 'string'
            },
            {
              title: 'Manufacturing Location',
              value: true,
              type: 'boolean'
            },
            {
              title: 'Manufacturing Location',
              value: false,
              type: 'boolean'
            },
            {
              title: 'Download Link',
              value: 'https://products.ecomedesstaging.com/impact-summary/17305',
              type: 'downloadLink'
            },
            {
              title: 'ShareLink',
              value: 'https://products.ecomedesstaging.com/impact-summary/17305',
              type: 'shareLink'
            },
            {
              title: 'Monthly Gallons Saved',
              value: 4567,
              type: 'number',
              showIcon: true
            },
            {
              title: 'Weekly, Gallons Saved',
              value: 'Contributes',
              type: 'string',
              showIcon: true
            }
          ]
        },
        {
          title: 'LEED®v4 BD+C: Hospitality',
          key: 'leed_hospitality',
          message: 'Values based on project parameters',
          data: [
            {
              title: 'Monthly Gallons Saved',
              value: 62093,
              type: 'number'
            },
            {
              title: 'Yearly, Gallons Saved',
              value: 912,
              type: 'currency'
            },
            {
              title: 'Weekly, Gallons Saved',
              value: 'Contributes',
              type: 'string'
            },
            {
              title: 'Manufacturing Location',
              value: true,
              type: 'boolean'
            },
            {
              title: 'Manufacturing Location',
              value: false,
              type: 'boolean'
            },
            {
              title: 'Download Link',
              value: 'https://products.ecomedesstaging.com/impact-summary/17305',
              type: 'downloadLink'
            },
            {
              title: 'ShareLink',
              value: 'https://products.ecomedesstaging.com/impact-summary/17305',
              type: 'shareLink'
            },
            {
              title: 'Monthly Gallons Saved',
              value: 4567,
              type: 'number',
              showIcon: true
            },
            {
              title: 'Weekly, Gallons Saved',
              value: 'Contributes',
              type: 'string',
              showIcon: true
            }
          ]
        },
        {
          title: 'Manufacturing location',
          key: 'manufacturing_location',
          data: [
            {
              location: 'Seattle, WA 98134',
              environmental_product_declaration: {
                title: 'Environmental Product Declaration (EPD)',
                url: '',
                urlTitle: 'Product Specific (4789532059.135.1)',
                certified: 'Certified by UL Environment',
                date: '2025-07-01',
                preconsumer: '9',
                postconsumer: '0'
              }
            },
            {
              location: 'Vancouver, BC V3M 5R1',
              environmental_product_declaration: {
                title: 'Environmental Product Declaration (EPD)',
                url: '',
                urlTitle: 'Product Specific (4789532059.135.1)',
                certified: 'Certified by UL Environment',
                date: '2025-07-01',
                preconsumer: '9',
                postconsumer: '0'
              }
            },
            {
              location: 'Calgary, AB T2C 2A4',
              environmental_product_declaration: {
                title: 'Environmental Product Declaration (EPD)',
                url: '',
                urlTitle: 'Product Specific (4789532059.135.1)',
                certified: 'Certified by UL Environment',
                date: '2025-07-01',
                preconsumer: '9',
                postconsumer: '0'
              }
            }
          ]
        }
      ]
    }
  ]
};
