/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { RefObject, useState } from 'react';

import { DownloadPdf } from '../../assets/svgs/downloadPdf';
import { handlePDFDownload } from '../../utils/pdf';
import { pdfStyle } from '../../utils/pdf.styles';
import RCButton from '../shared/Button/Button';
import Loader from '../shared/Loader/loader';

// import ecomedes from './../../assets/images/ecomedes.png';
import Padding from './Padding';

import style from './sustainabilitySummary.module.scss';

export interface PageTemplateProps {
  pageNum: number;
  totalPages: number;
}

export const generatePageTemplate = (
  { pageNum, totalPages }: PageTemplateProps,
  url: string
): JSX.Element => (
  <div
    style={{
      position: 'absolute',
      bottom: '0px',
      left: '0',
      width: '100%'
    }}>
    <style>{pdfStyle()}</style>
    <span style={{ position: 'absolute', bottom: '60px', left: '45%', width: '100%' }}>
      Page {pageNum} of {totalPages}
    </span>
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: '49px',
        backgroundColor: '#F2F2F2',
        padding: '12px 0px',
        position: 'absolute',
        bottom: '2px',
        left: '0'
      }}>
      <div
        style={{
          fontSize: '20px',
          fontWeight: 900,
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          marginTop: '0rem',
          marginRight: '.5rem'
        }}>
        Powered by
      </div>
      <img
        src={url}
        alt="ecomedes"
        style={{ marginTop: '5px', width: '131.538px', height: '24px' }}
      />
    </div>
  </div>
);

const Header = ({
  pdfRef,
  // eslint-disable-next-line no-unused-vars
  name,
  trackFn,
  projectName,
  address,
  toggle
}: {
  pdfRef: RefObject<HTMLDivElement>;
  name: string | null;
  projectName: any;
  address: string | null;
  toggle?: React.KeyboardEventHandler<any> | React.MouseEventHandler<any>;
  trackFn: (action: string) => {};
}): JSX.Element => {
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isButtonFocused, setIsButtonFocused] = useState(false);

  return (
    <Padding>
      <header className={`${style.header} impactSummaryHeaderSecttion`}>
        <div className={`${style.impactSummaryTitle} impactSummaryTitlepdf`}>{name}</div>
        <div className={style.downloadButtons}>
          <RCButton
            onClick={toggle as any}
            className={`${style.downloadButtonContent} hidePdfElements`}
            variant="primary">
            <span className={style.downloadPdfText}>CUSTOMIZE VIEW</span>
          </RCButton>

          <span
            onMouseOver={() => setIsButtonFocused(true)}
            onMouseLeave={() => setIsButtonFocused(false)}>
            <RCButton
              onClick={() => {
                handlePDFDownload(
                  pdfRef,
                  setIsPdfLoading,
                  generatePageTemplate,
                  name,
                  projectName,
                  address
                );
                trackFn('download-pdf');
              }}
              className={`${style.downloadButtonContent} hidePdfElements`}
              variant="primary">
              {isPdfLoading ? <Loader /> : <DownloadPdf isButtonFocused={isButtonFocused} />}
              <span className={style.downloadPdfText}>DOWNLOAD PDF</span>
            </RCButton>
          </span>
        </div>
      </header>
    </Padding>
  );
};

export default Header;
