import style from './sustainabilitySummary.module.scss';
export const ModalFooter = ({ onSave, onCancel }: any): JSX.Element => (
  <div className={style.modalFooter}>
    <button onClick={onSave} className={`${style.modalButton} ${style.modalFooterSaveBtn}`}>
      SAVE
    </button>
    <button onClick={onCancel} className={`${style.modalButton} ${style.modalFooterCloseBtn}`}>
      CLOSE
    </button>
  </div>
);
