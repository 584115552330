import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import { ProductDetail } from '../components/ProductDetail';
import { ErrorBoundaryWrapper } from '../components/shared/ErrorBoundary/ErrorBoundary';

import style from './sustainabilitySummaryPage.module.scss';
let mountedproductElement: HTMLElement | null = null;

export function mountProductDetail(element: HTMLElement, reportData: Record<string, any>): void {
  const defaultProps = {
    data: reportData
  };

  createRoot(element).render(<ProductPage {...defaultProps} />);
  mountedproductElement = element;
}

export function unmountProductDetail(): void {
  if (mountedproductElement) {
    ReactDOM.unmountComponentAtNode(mountedproductElement);
    mountedproductElement = null;
  }
}
const ProductPage: React.FC<{ data?: Record<string, any> }> = ({ data }) => {
  const pdfExportComponent = React.useRef<HTMLDivElement>(null);

  useEffect(
    () => () => {
      unmountProductDetail();
    },
    []
  );

  return (
    <div className={style.sustainabilitySummaryPageContainer}>
      <div ref={pdfExportComponent}>
        <ErrorBoundaryWrapper>
          <ProductDetail data={data} pdfRef={pdfExportComponent} />
        </ErrorBoundaryWrapper>
      </div>
    </div>
  );
};

export default ProductPage;
