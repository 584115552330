import { StepperProps } from '../../interface/projectSummary';
import IconBox from '../shared/IconBox/iconBox';

import style from './projectEvaluation.module.scss';

const StatisticItem = ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  icon: Icon,
  amount,
  title,
  symbol,
  className
}: StepperProps): JSX.Element => (
  <div className={`${style.statisticItem} statisticItemPdf`}>
    <IconBox icon={Icon} className={className} />
    <div className={style.statisticItemValue}>
      <div className={style.staticItemTitle}>{title}</div>
      <div className={style.statValue}>
        {amount ? (
          <>
            {symbol}
            {(1 * amount).toLocaleString()}
          </>
        ) : (
          0
        )}
      </div>
    </div>
  </div>
);

export default StatisticItem;
