import React from 'react';

import { AddProjectIcon } from '../../assets/svgs/addProject';

import style from './productDetails.module.scss';
export const ModalHeader: React.FC = () => (
  <div className={style.modalHeaderAddProjectContainer}>
    <div className={style.modalHeaderTitleandIcon}>
      <AddProjectIcon />
      <h1 className={style.modalHeaderLabel}>Add to project</h1>
    </div>
    <p className={style.modalHeaderDescription}>
      Create a new project or add to an existing project
    </p>
  </div>
);
