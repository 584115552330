import { IImpact } from '../../SustainabilitySummary';

import style from './postiveImpactSummaryCard.module.scss';
export const PostiveImpactSummaryCard = ({
  item,
  isCarbonFootPrint = false
}: {
  item: IImpact;
  isCarbonFootPrint?: boolean;
}): JSX.Element => (
  <div
    className={
      isCarbonFootPrint ? style.postiveImpactSummaryCarbonCard : style.postiveImpactSummaryCard
    }>
    <div className={`${style.postiveImpactSummaryCardHeader}`}>
      <div
        className={`${!item.hasBackground ? style.svgCover : isCarbonFootPrint ? style.carbonFootPrintIcon : ''}`}>
        {item?.icon}
      </div>
      <h4 className={`${style.postiveImpactSummaryHeading} postiveImpactSummaryHeadingPdf`}>
        {item?.qunatity?.toLocaleString()}
      </h4>
    </div>
    <p
      className={
        isCarbonFootPrint ? style.carbonFootPrintCardBody : style.postiveImpactSummaryCardBody
      }>
      <span
        className={
          item.bolder === 'start' ? style.postiveImpactSummaryBold : style.carbonPrintFont
        }>
        {item?.start}
      </span>
      <span
        className={
          item.bolder === 'middle' ? style.postiveImpactSummaryBold : style.carbonPrintFont
        }>
        {item?.middle}
      </span>
      <span
        className={item.bolder === 'end' ? style.postiveImpactSummaryBold : style.carbonPrintFont}>
        {item?.end}
      </span>
    </p>
  </div>
);
