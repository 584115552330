import { ReactNode } from 'react';

import style from './sustainabilitySummary.module.scss';

type PaddingProps = {
  children: ReactNode;
};
export const Padding: React.FC<PaddingProps> = ({ children }) => (
  <div className={`${style.padding} paddingPdf`}>{children}</div>
);
export default Padding;
