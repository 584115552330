import React from 'react';
import { useTranslation } from 'react-i18next';

import BuildingIcon from '../../assets/svgs/building';
import HouseIcon from '../../assets/svgs/house';
import LocationIcon from '../../assets/svgs/location';
import SizeIcon from '../../assets/svgs/size';
import { ProjectDetailsProps } from '../../interface/projectSummary';

import Padding from './Padding';

import style from './projectEvaluation.module.scss';
const ProjectDetails: React.FC<ProjectDetailsProps> = ({
  postalCode,
  projectSize,
  clientName,
  address,
  description
}) => {
  const { t } = useTranslation();

  return (
    <Padding>
      <div className={style.projectDetails}>
        <h2 className={`${style.ecomedesTitle} ${!description && style.visibilityHiddenClass}`}>
          {description && <span className={style.ecomedesSubTitle}> {description} </span>}
        </h2>
        <div className={`${style.detailItems} detailItemsPdf`}>
          <div className={style.detailsSection}>
            <div className={style.detailItem}>
              <LocationIcon />
              <span className={`${style.detailItemTitle} ${style.postalCodeKey}`}>
                {t('projectEval.projectDetails.postalCode')}:
              </span>
              <span>{postalCode || '-'}</span>
            </div>
          </div>
          <div className={style.verticalLine}></div>
          <div className={style.detailsSection}>
            <div className={style.detailItem}>
              <SizeIcon />
              <span className={`${style.detailItemTitle} ${style.projectSizeKey}`}>
                {t('projectEval.projectDetails.projectSize')}:
              </span>
              <span>{projectSize === 'null' ? '-' : (1 * projectSize).toLocaleString()}</span>
            </div>
          </div>
          <div className={style.verticalLine}></div>
          <div className={style.detailsSection}>
            <div className={style.clientDetails}>
              <BuildingIcon />
              <span className={`${style.detailItemTitle} ${style.clientNameKey}`}>
                {t('projectEval.projectDetails.clientName')}:
              </span>
              <span className={style.clientNameValue}>{clientName || '-'}</span>
            </div>
          </div>
          <div className={style.verticalLine}></div>
          <div className={style.detailsSection}>
            <div className={style.detailItem}>
              <HouseIcon />
              <span className={style.detailItemTitle}>
                {t('projectEval.projectDetails.address')}:
              </span>
              <span className={style.detailItemAddress}>{address || '-'}</span>
            </div>
          </div>
        </div>
      </div>
    </Padding>
  );
};

export default ProjectDetails;
