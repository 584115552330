import ShareIcon from '../../../assets/svgs/sharableIcon';
import { filterArrayNullValues } from '../../../utils/filterArrayNullValues';
import { convertFractionToPercentage, isBooleanValue, isPercentValue } from '../../../utils/util';
import { ManufacturingLocation } from '../ManufacturingLocation/ManufacturingLocation';
import ToolTip from '../Tooltip/Tooltip';

import styles from './accordionRow.module.scss';
const renderMap = {
  LEVEL: (item: any) =>
    item?.kind ? (typeof item.kind === 'number' ? `LEVEL ${item.kind}` : item.kind) : null,
  ID_AND_KIND: (item: any) => (item?.kind && item?.id && !item?.version ? `(${item.id})` : null),
  ID_AND_VERSION: (item: any) =>
    !item?.kind && item?.id && item?.version ? `${item.id} (version ${item.version})` : null,
  KIND_AND_VERSION: (item: any) =>
    item?.kind && !item?.id && item?.version ? `(version ${item.version})` : null,
  ID_ONLY: (item: any) => (item.id && !item?.kind && !item?.version ? `${item.id}` : null),
  VERSION_ONLY: (item: any) =>
    item.version && !item?.id && !item?.kind ? `version ${item.version}` : null,
  ID_VERSION_AND_KIND: (item: any) =>
    item?.id && item?.kind && item?.version ? `(${item.id}, version ${item.version})` : null
};

export const RenderCerticateValues = ({
  value,
  renderUrlValue
}: {
  value: any;
  renderUrlValue?: any;
}): JSX.Element => (
  <div>
    {!renderUrlValue
      ? value?.map((item: any, index: number) => (
          <div className={styles.accordionRowCertificateValue} key={`${item}-${index}`}>
            <a href={item.url} className={styles.accordionRowCertificateValueSpecifier}>
              <p>{renderMap['LEVEL'](item)}</p>
              <p>
                {renderMap['ID_AND_KIND'](item) ||
                  renderMap['ID_AND_VERSION'](item) ||
                  renderMap['KIND_AND_VERSION'](item) ||
                  renderMap['ID_AND_VERSION'](item) ||
                  renderMap['ID_ONLY'](item) ||
                  renderMap['VERSION_ONLY'](item) ||
                  renderMap['ID_VERSION_AND_KIND'](item)}
              </p>
            </a>

            {item?.product_type && <p>{`product type : ${item.product_type}`}</p>}
            {item?.country && <p>{`Country : ${item.country}`}</p>}
            {item?.standard_number && <p>{item.standard_number}</p>}
            {item?.certifier && (
              <div className={styles.accordionRowCertificateValueSpecifier}>
                <p>{`Certified by`}</p>
                {item?.source ? (
                  <a href={item.source}>{item.certifier}</a>
                ) : (
                  <p>{item.certifier}</p>
                )}
              </div>
            )}
            {item?.third_party_verified && '3rd Party Verified'}
            {item?.starts && !item.ends ? (
              <div className={styles.accordionRowCertificateValueSpecifier}>
                <p>{`certified date `}</p>
                <p>{`${item?.starts.split('T')[0]}`}</p>
              </div>
            ) : item?.starts && item?.ends ? (
              <div className={styles.accordionRowCertificateValueSpecifier}>
                <p>{`valid`}</p>
                <p>{`${item?.starts.split('T')[0]} to ${item?.ends.split('T')[0]}`}</p>
              </div>
            ) : (
              item?.ends && (
                <div className={styles.accordionRowCertificateValueSpecifier}>
                  <p>{`valid until ${item?.ends.split('T')[0]}`}</p>
                </div>
              )
            )}
            <div className={styles.materialType}>
              {item?.cc_material_health && (
                <p>{`Material Health :${item?.cc_material_health || ''}`}</p>
              )}
              {item.cc_material_reutilization && (
                <p>{`Material Reutilization :${item?.cc_material_reutilization || ''}`}</p>
              )}

              {item?.cc_renewable_energy_and_carbon_management && (
                <p>
                  {`Renewable Energy & Carbon Management :
                   ${item?.cc_renewable_energy_and_carbon_management || ''}`}
                </p>
              )}
              {item?.cc_social_fairness && (
                <p>{`Social Fairness :${item?.cc_social_fairness || ''}`}</p>
              )}
              {item?.cc_water_stewardship && (
                <p>{`Water Stewardship :${item?.cc_water_stewardship || ''}`}</p>
              )}
            </div>
          </div>
        ))
      : value?.map((item: any, index: number) => (
          <a key={index} href={item?.url} className={styles.accordionRowCertificateValueSpecifier}>
            {'Document'}
          </a>
        ))}
  </div>
);

export const RenderValue = ({
  value,
  type = 'string',
  tooltipText = 'tooltipText',
  isToolTip = false,
  isLensData = false,
  customAccordionRow = false
}: {
  value: any;
  type?: string;
  tooltipText?: string;
  isToolTip?: boolean;
  isLensData?: boolean;
  customAccordionRow?: boolean;
}): JSX.Element => {
  const manufacturingPlant = 'manufacturing-plant';
  const renderers: { [key: string]: () => JSX.Element | string } = {
    number: () => value,
    bool: () => isBooleanValue(value),
    string: () => (Array.isArray(value) ? value.join(', ') : value),
    url: () => (
      <div className={styles.iconLink}>
        <ShareIcon />
        <a href={value} className={styles.accordionRowSpacing}>
          {`External Link`}
        </a>
      </div>
    ),
    certificate: () => {
      let renderedComponent: React.ReactNode = '_';

      if (typeof value === 'object' && Array.isArray(value.value)) {
        renderedComponent = <RenderCerticateValues value={value.value} />;
      } else if (
        Array.isArray(value) &&
        value.length === 1 &&
        Object.keys(value[0]).length === 1 &&
        value[0].hasOwnProperty('url')
      ) {
        renderedComponent = <RenderCerticateValues value={value} renderUrlValue />;
      } else if (Array.isArray(value)) {
        renderedComponent = <RenderCerticateValues value={value} />;
      }

      return <div>{renderedComponent}</div>;
    },

    csi: () => (Array.isArray(value) ? value.join(', ') : value),
    [manufacturingPlant]: () =>
      value === null || value === '' || filterArrayNullValues(value).length
        ? '-'
        : !filterArrayNullValues(value).length && Array.isArray(value)
          ? value?.map((val) => (
              <div key={val.id} className={styles.manufacturingPlant}>
                <p>{val.city}</p>
                {val.post_rc && <p>Post-consumer: {convertFractionToPercentage(val.post_rc)}</p>}
                {val.pre_rc && <p>Pre-consumer: {convertFractionToPercentage(val.pre_rc)}</p>}
              </div>
            ))
          : value,
    percent: () => isPercentValue(value),
    date: () => value && value?.split('T')[0]
  };

  if (!value) {
    return <div className={styles.accordionRowValue}>-</div>;
  }

  const renderValue = renderers[type || 'string'];

  return (
    <div
      className={
        isLensData
          ? styles.accordionRowValue
          : customAccordionRow
            ? styles.customRowWalue
            : styles.accordionProductContributionValue
      }>
      {isToolTip ? <ToolTip tooltipText={tooltipText}>{renderValue()}</ToolTip> : renderValue()}
    </div>
  );
};

const AccordionRow = ({
  title,
  value,
  type,
  creditName,
  tooltipText,
  isToolTip,
  city,
  postRc,
  preRc,
  state,
  zipcode,
  isManufactureLocation,
  isLensData,
  isLightContent,
  index,
  dataLength,
  isCreditDetails,
  nestedRow = false,
  customAccordionRow = false
}: {
  title?: string;
  value?: any;
  type?: string;
  creditName?: string;
  tooltipText?: string;
  isToolTip?: boolean;
  city?: string;
  postRc?: number;
  preRc?: number;
  state?: string;
  zipcode?: string;
  locationIndex?: number;
  isManufactureLocation?: boolean;
  isLensData?: boolean;
  isLightContent?: boolean;
  index?: number;
  dataLength?: number | undefined;
  isCreditDetails?: boolean;
  nestedRow?: boolean;
  customAccordionRow?: boolean;
}): JSX.Element => {
  const locationProps = {
    city,
    postRc,
    preRc,
    state,
    zipcode,
    index,
    dataLength
  };

  return (
    <div>
      {!isManufactureLocation ? (
        <div
          className={`${(dataLength && index === dataLength - 1) || nestedRow ? styles.row : styles.accordionRow} ${isCreditDetails ? styles.creditDetails : customAccordionRow ? styles.customAccordionRow : ''}`}>
          {title || creditName ? (
            <div
              className={`${isLensData ? styles.accordionRowBoldTitle : styles.accordionProductContributionTitle}
            ${isLightContent ? styles.accordionRowLightContent : styles.accordionRowBoldTitle}`}>
              {title} {creditName && `(${creditName})`}
            </div>
          ) : (
            ''
          )}
          {value && (
            <RenderValue
              value={value}
              type={type}
              tooltipText={tooltipText}
              isToolTip={isToolTip}
              isLensData={isLensData}
              customAccordionRow={customAccordionRow}
            />
          )}
        </div>
      ) : (
        <ManufacturingLocation {...locationProps} />
      )}
    </div>
  );
};

export default AccordionRow;
