export const Barrels = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <rect width="40" height="40" rx="8" fill="#F2F2F2" />
    <rect x="11" y="7" width="19" height="26" fill="#343A40" />
    <path
      d="M12.4325 24.8069C12.144 24.8069 11.9043 25.0421 11.9043 25.3351C11.9043 25.628 12.1395 25.8588 12.4325 25.8588H27.5634C27.8519 25.8588 28.0872 25.6236 28.0872 25.3351C28.0872 25.0466 27.8519 24.8069 27.5634 24.8069H12.4325Z"
      fill="#F2F2F2"
    />
    <path
      d="M18.4062 20.7546C18.4062 21.6556 19.1386 22.3924 20.0441 22.3924C20.9495 22.3924 21.6819 21.6601 21.6819 20.7546C21.6819 20.2486 21.056 19.2411 20.0441 18.1093C19.0321 19.2366 18.4062 20.2486 18.4062 20.7546Z"
      fill="#F2F2F2"
    />
    <path
      d="M12.9912 15.8323V24.1412H27.0081V15.8323H12.9912ZM20.0485 23.0582C18.779 23.0582 17.7449 22.024 17.7449 20.7546C17.7449 19.8136 18.8989 18.3889 19.5913 17.6254C19.7067 17.4967 19.8754 17.4213 20.0485 17.4213C20.2216 17.4213 20.3902 17.4967 20.5056 17.6254C21.1936 18.3889 22.3476 19.8136 22.3476 20.7546C22.3476 22.024 21.3134 23.0582 20.044 23.0582H20.0485Z"
      fill="#F2F2F2"
    />
    <path d="M27.0081 26.5247H12.9912V29.9512H27.0081V26.5247Z" fill="#F2F2F2" />
    <path
      d="M12.4325 30.6213C12.144 30.6213 11.9043 30.8566 11.9043 31.1451C11.9043 31.4336 12.1395 31.6733 12.4325 31.6733H27.5634C27.8519 31.6733 28.0872 31.438 28.0872 31.1451C28.0872 30.8521 27.8519 30.6213 27.5634 30.6213H12.4325Z"
      fill="#F2F2F2"
    />
    <path
      d="M28.0916 8.85041C28.0916 8.5619 27.8564 8.32666 27.5679 8.32666H12.4325C12.144 8.32666 11.9043 8.5619 11.9043 8.85041C11.9043 9.1256 12.1173 9.34308 12.3837 9.36528H27.6078C27.8741 9.33864 28.0872 9.12116 28.0872 8.85041H28.0916Z"
      fill="#F2F2F2"
    />
    <path
      d="M20 0C8.95251 0 0 8.95251 0 20C0 31.0475 8.95251 40 20 40C31.0475 40 40 31.0475 40 20C40 8.95251 31.0431 0 20 0ZM28.7572 14.6427C28.7572 15.2641 28.2779 15.7656 27.6742 15.8233V24.1545C28.2779 24.2122 28.7572 24.7137 28.7572 25.3351C28.7572 25.9565 28.2779 26.4581 27.6742 26.5158V29.9645C28.2779 30.0222 28.7572 30.5237 28.7572 31.1451C28.7572 31.802 28.2246 32.3391 27.5677 32.3391H12.4323C11.7754 32.3391 11.2383 31.802 11.2383 31.1451C11.2383 30.5237 11.7177 30.0222 12.3213 29.9645V26.5158C11.7177 26.4581 11.2383 25.9565 11.2383 25.3351C11.2383 24.7137 11.7177 24.2122 12.3213 24.1545V15.8233C11.7177 15.7656 11.2383 15.2641 11.2383 14.6427C11.2383 14.0213 11.7177 13.5198 12.3213 13.4621V10.0311C11.7177 9.97337 11.2383 9.47182 11.2383 8.85042C11.2383 8.19352 11.771 7.6609 12.4323 7.6609H27.5632C28.2201 7.6609 28.7528 8.19352 28.7528 8.85042C28.7528 9.47182 28.2734 9.97337 27.6698 10.0311V13.4621C28.2734 13.5198 28.7528 14.0213 28.7528 14.6427H28.7572Z"
      fill="#F2F2F2"
    />
    <path d="M27.0081 10.0399H12.9912V13.4487H27.0081V10.0399Z" fill="#F2F2F2" />
    <path
      d="M12.3876 14.1278C12.1213 14.1544 11.9082 14.3719 11.9082 14.6427C11.9082 14.9134 12.1213 15.1354 12.3876 15.1575H27.6117C27.878 15.1309 28.0911 14.9134 28.0911 14.6427C28.0911 14.3719 27.878 14.15 27.6117 14.1278H12.3876Z"
      fill="#F2F2F2"
    />
  </svg>
);
